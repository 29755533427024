import React from 'react';
import { Link } from 'react-router-dom'
import strings from '../../components/Language';
import { Helmet } from 'react-helmet';

import { api } from '../../components/config/api';
import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import { getToken, getUserData, number_format, storeUserData } from '../../components/Util';

import Header from '../../components/Layout/Header';
import SideBarWeb from '../../components/Layout/SideBarWeb';
import SideBarMobile from '../../components/Layout/SideBarMobile';
import LatestNews from '../../components/Layout/LatestNews';

class FriendListScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: localStorage.getItem("language"),
			is_loading: false,
			access_token: "",
			user_data: {},
			credit_data: {},
			friends_message: "",
			data: []
		}
	}

	async componentDidMount() {
		let self = this;
		window.scrollTo(0, 0)
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			var user_data = getUserData();
			self.setState({
				access_token: token,
				user_data: (user_data && user_data.user) ? user_data.user : {},
				credit_data: (user_data && user_data.credit) ? user_data.credit : '',
			});
			setTimeout(() => {
				self.getData(api.friends, false)
			});
		}
	}

	getData(API, is_back = false) {
		if (API) { } else { return false; }
		var self = this;
		axios({
			method: 'GET',
			url: API,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				self.setState({
					data: (ress.data.data && ress.data.data.rows && ress.data.data.rows.data) ? ress.data.data.rows.data : [],
					prev_page_url: (ress.data.data && ress.data.data.rows && ress.data.data.rows.prev_page_url) ? ress.data.data.rows.prev_page_url : '',
					next_page_url: (ress.data.data && ress.data.data.rows && ress.data.data.rows.next_page_url) ? ress.data.data.rows.next_page_url : '',
					is_back: is_back,
					friends_message: ress.data.message,
				});
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
		}).catch(function (e) {
			//toast.error(e.message);
		});
	};

	copyRefcode() {
		navigator.clipboard.writeText((this.state.user_data && this.state.user_data.username) ? api.MainURL + "?refcode=" + this.state.user_data.username : '-')
		toast.success("Meta ID/Refcode Copied!");
	}

	copyWalletAddress() {
		navigator.clipboard.writeText((this.state.user_data && this.state.user_data.wallet_address) ? this.state.user_data.wallet_address : '-')
		toast.success("Wallet Address Copied!");
	}
	render() {
		return (
			<>
				<Helmet><title>{"FUTURA | Friend List"}</title></Helmet>
				<div className="dashboard">
					<Header />

					<div className="container-fluid">
						<div className="row">
							<SideBarWeb />

							<>
								<div className="col">
									<br />
									<h1>{strings["Hello"]}!<br /> {(this.state.user_data && this.state.user_data.username) ? this.state.user_data.username : '-'}</h1>
									<div className="panel">
										<div className="row">
											<div className="col-md-6">
												<div className="pocket">
													<div className="px-2">
														<picture>
															<img className='btnimg' src={(this.state.user_data && this.state.user_data.avatar_url) ? this.state.user_data.avatar_url : "assets/images/avtr.png"} alt="" />
														</picture>
													</div>
													<div className="box">
														<div className="row">
															<div className="col-auto mb-2">
																{strings["Meta ID/Refcode"]}:
															</div>
															<div className="col-auto mb-2">
																{(this.state.user_data && this.state.user_data.username) ? this.state.user_data.username : '-'}
															</div>
															<div className="col mb-2 text-end">
																{
																	this.state.user_data && this.state.user_data.level==1?
																		<a onClick={() => this.copyRefcode()} >{strings["Copy ReferID"]}</a>
																	:
																	null
																}
															</div>
														</div>
														<div className="row">
															<div className="col-auto mb-2">
																{strings["Email Address"]}:
															</div>
															<div className="col-auto mb-2">
																{(this.state.user_data && this.state.user_data.email_masked) ? this.state.user_data.email_masked : '-'}
															</div>
															<div className="col mb-2 text-end">
																<a >{/*strings["Edit"]*/}</a>
															</div>
														</div>
														<div className="row">
															<div className="col-auto mb-2">
																{strings["Wallet Address"]}:
															</div>
															<div className="col-auto mb-2">
																{(this.state.user_data && this.state.user_data.wallet_address_masked) ? this.state.user_data.wallet_address_masked : '-'}
															</div>
															<div className="col mb-2 text-end">
																<a onClick={() => this.copyWalletAddress()} >{strings["Copy Address"]}</a>
															</div>
														</div>
														<h3>{strings["Pocket Balance"]}</h3>
														<div className="row">
															<div className="col-auto">
																<div className="row">
																	<div className="col-auto mb-1">
																		{strings["FUT"]}:&nbsp;&nbsp;
																	</div>
																	<div className="col-auto mb-1">
																		{(this.state.credit_data && this.state.credit_data.fud) ? number_format(this.state.credit_data.fud, 2, '.', ',') : '0'}
																	</div>
																</div>
																<div className="row green">
																	<div className="col-auto mb-1">
																		{strings["FUTI"]}:&nbsp;
																	</div>
																	<div className="col-auto mb-1">
																		{(this.state.credit_data && this.state.credit_data.fudi) ? number_format(this.state.credit_data.fudi, 2, '.', ',') : '0'}
																	</div>
																</div>
																<div className="row blue">
																	<div className="col-auto mb-1">
																		{strings["FUTE"]}:
																	</div>
																	<div className="col-auto mb-1">
																		{(this.state.credit_data && this.state.credit_data.fude) ? number_format(this.state.credit_data.fude, 2, '.', ',') : '0'}
																	</div>
																</div>
																<div className="row">
																	<div className="col-auto">
																		{strings["USDT"]}:
																	</div>
																	<div className="col-auto">
																		{(this.state.credit_data && this.state.credit_data.usdt) ? number_format(this.state.credit_data.usdt, 2, '.', ',') : '0'}
																	</div>
																</div>
															</div>
															<div className="col pt-2 text-end">
																
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className="players">
											<div className='row text-center mb-3'>
												<b>{this.state.friends_message}</b>
											</div>
											<div className="frame">
												{this.state.data && this.state.data.length != 0 ?
													<>
														{this.state.data.map((item, i) =>
															<div>
																<div className="num">{item.direct_children_count}</div>
																<div className="avtr">
																	<img src={item.avatar_url} alt="" />
																	<div className="overlay">{item.username ?? "-"}<br />
																		<a onClick={() => this.getData(api.friends + "?user=" + item.id, true)}  className="btn btn-primary rounded-pill">{strings["View"]}</a>
																	</div>
																</div>
																<p>{number_format((item.statistic && item.statistic.droup_bv) ? item.statistic.droup_bv : 0, 4, '.', ',')}</p>
															</div>
														)}
													</>
													:
													<div className='row text-center'>
														<span>{strings["No Friends available"]}</span>
													</div>
												}
											</div>

										</div>
										{this.state.is_back ?
											<div className="text-center">
												<a onClick={() => this.getData(api.friends)}  className="btn btn-primary rounded-pill">{strings["My All Members"]}</a>
											</div>
											: null}
									</div>
								</div>
							</>

							<LatestNews />

						</div>
					</div>

				</div>
				<SideBarMobile />
			</>
		);
	}
}

export default FriendListScreen;
