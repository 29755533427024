const IN = {
    "confirm": "Confirm",
    "coming_soon": "Coming Soon",
    "processing": "Transaction submitted. Please wait",
    "error": "Error",
    "success": "Success",
    "close": "Close",
    "language": "Language",

    "Latest": "Latest",
    "News/Updates": "News/Updates",
    "View All": "View All",
    "Dashboard": "Dashboard",
    "NODES": "NODES",
    "SWAP": "SWAP",
    "FRIEND LIST": "FRIEND LIST",
    "P2P LISTING": "P2P LISTING",
    "Logout": "Logout",
    "We could not find": "We could not find",
    "page": "page",
    "Meta-Account": "Meta-Account",
    "Loading": "Loading",
    "Create Meta ID": "Create Meta ID",
    "Enter Your Email Address": "Enter Your Email Address",
    "Enter OTP": "Enter OTP",
    "Request OTP": "Request OTP",
    "Enter Refcode": "Enter Refcode",
    "Create Account": "Create Account",
    "Hello": "Hello",
    "Meta ID/Refcode": "Meta ID/Refcode",
    "Copy ReferID": "Copy ReferID",
    "Email Address": "Email Address",
    "Edit": "Edit",
    "Wallet Address": "Wallet Address",
    "Copy Address": "Copy Address",
    "Pocket Balance": "Pocket Balance",
    "FUT": "FUT",
    "FUTI": "FUTI",
    "FUTE": "FUTE",
    "FUTEP": "FUTEP",
    "Deposit": "Deposit",
    "Withdraw": "Withdraw",
    "Transfer": "Transfer",
    "My Placement Release": "My Placement Release",
    "Claim": "Claim",
    "Vault Balance": "Vault Balance",
    "POCKET": "POCKET",
    "Date/Time": "Date/Time",
    "Description": "Description",
    "Amount": "Amount",
    "Balance": "Balance",
    "Prev Page": "Prev Page",
    "Next Page": "Next Page",
    "View": "View",
    "No Friends available": "No Friends available",
    "My All Members": "My All Members",
    "USDT": "USDT",
    "IN VAULT": "IN VAULT",
    "PURCHASE NOW": "PURCHASE NOW",
    "QUICK BUY": "QUICK BUY",
    "QUICK SELL": "QUICK SELL",
    "BUY": "BUY",
    "SELL": "SELL",
    "Quick Buy": "Quick Buy",
    "Quick Buy operates on a first-come, first-serve basis, utilizing available FUTI in the listing to make purchases swiftly.": "Quick Buy operates on a first-come, first-serve basis, utilizing available FUTI in the listing to make purchases swiftly.",
    "Request OTP": "Request OTP",
    "Exchange Now": "Exchange Now",
    "MY BUY ORDER": "MY BUY ORDER",
    "Quick Sell": "Quick Sell",
    "Quick Sell follows a first-come, first-serve approach, utilizing the total available FUTE in the listing to fulfill orders promptly to interested buyers.": "Quick Sell follows a first-come, first-serve approach, utilizing the total available FUTE in the listing to fulfill orders promptly to interested buyers.",
    "Enter FUEDE Amount": "Enter FUEDE Amount",
    "Enter OTP": "Enter OTP",
    "My Sell Order": "My Sell Order",
    "Seller": "Seller",
    "Amount of FUTI Listed for Sale": "Amount of FUTI Listed for Sale",
    "Purchased Amount": "Purchased Amount",
    "Amount On Listing": "Amount On Listing",
    "Sold Amount": "Sold Amount",
    "Creat AD to Sell": "Creat AD to Sell",
    "After each completed transaction, FUTI will instantly reflect in your pocket balance.": "After each completed transaction, FUTI will instantly reflect in your pocket balance.",
    "Enter FUTI Amount": "Enter FUTI Amount",
    "Creat AD to Buy": "Creat AD to Buy",
    "After each completed transaction, FUTE will instantly reflect in your pocket balance.": "After each completed transaction, FUTE will instantly reflect in your pocket balance.",
    "SWAP": "SWAP",
    "CROSS CHAIN": "CROSS CHAIN",
    "Choose Token": "Choose Token",
    "Wallet Balance": "Wallet Balance",
    "Swap Now": "Swap Now",
    "Choose Chain": "Choose Chain",
    "Skip": "Skip",
    "Disconnect": "Disconnect",
    "Are you sure?": "Are you sure?",
    "Are you sure you want to logout?": "Are you sure you want to logout?",
    "Are you sure you want to disconnect?": "Are you sure you want to disconnect?",
    "Cancel": "Cancel",
    "Username": "Username",
    "Enter Username": "Enter Username",
    "Enter Amount": "Enter Amount",
    "Transfer": "Transfer",
    "Withdrawal": "Withdrawal",
    "Total FUTI Release": "Total FUTI Release",
    "Total FUTE Release": "Total FUTE Release",
    "Records": "Records",
    "ID": "ID",
    "Descriptions": "Descriptions",
    "Status": "Status",
    "Convert": "Convert",
    "Pocket Balance": "Pocket Balance",
    "Deduct From Wallet": "Deduct From Wallet",
    "Deduct From Pocket": "Deduct From Pocket",
    "Amount withdrawal": "Amount withdrawal",
    "Loading ...": "Loading ...",
    "Copy Wallet address": "Copy Wallet address",
    "Wallet Balance": "Wallet Balance",
    "Approve USDT": "Approve USDT",
    "Approve FUT": "Approve FUT",
    "Scan to Deposit": "Scan to Deposit",
    "Connect To Wallet": "Connect To Wallet",
    "Copy Address": "Copy Address",
    "Terms & Conditions": "Terms & Conditions",
    "1. User only FSC-20 USDT tokens to make deposits. Non-Compatible tokens May result to the loss of your funds. Platform will not be responsible for such loss.": "1. User only FSC-20 USDT tokens to make deposits. Non-Compatible tokens May result to the loss of your funds. Platform will not be responsible for such loss.",
    "2. Finds are accredited to your account only after 6 network confirmations.": "2. Finds are accredited to your account only after 6 network confirmations.",
    "Confirm Transaction": "Confirm Transaction",
    "Confirm node purchase?": "Confirm node purchase?",
    "Report": "Report",
    "Back": "Back",
    "Token Type": "Token Type",
    "Read More":"Read More",
};
export default IN;