import React from 'react';
import { Link } from 'react-router-dom'
import strings from '../../components/Language';
import { getToken, getUserData, number_format, storeUserData } from '../../components/Util';
import { Helmet } from 'react-helmet';

import { api } from '../../components/config/api';
import Modal from 'react-modal';
import Iframe from 'react-iframe';
import loading from './../../assets/images/loading.gif';
import cancel from './../../assets/images/cancel.png';

import Header from '../../components/Layout/Header';
import SideBarWeb from '../../components/Layout/SideBarWeb';
import SideBarMobile from '../../components/Layout/SideBarMobile';
import LatestNews from '../../components/Layout/LatestNews';
import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import { MenuItem, Select } from '@mui/material';
import QRCode from "react-qr-code";
import swal from 'sweetalert';


class DashboardScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: localStorage.getItem("language"),
			isLoading: false,
			is_loading_main: false,
			is_load_avatar: false,
			access_token: "",
			is_modal: false,
			is_modal_transfer: false,
			is_modal_deposit: false,
			is_modal_withdrawal: false,
			is_modal_convert: false,
			is_show_withdrawal_records: false,
			is_show_withdrawal: true,
			random_key: 1,

			user_data: {},
			credit_data: {},
			activeTab: "FUTI",
			deposit_tab: "USDT",
			credit_name: "FUTI",

			transfer_credit_name: "USDT",
			username: "",
			amount: 0,

			withdrawal_credit_name: "FUT",
			withdrawal_amount: 0,

			convert_amount: 0,

			errorMessage: [],

			creditListData: [],
			prev_page_url: "",
			next_page_url: "",
			withdrawalListData_load: false,
			withdrawalListData: [],
			withdrawal_prev_page_url: "",
			withdrawal_next_page_url: "",

			avatar: "",

		}
	}

	async componentDidMount() {
		let self = this;
		self.setState({ is_loading_main: true });
		window.scrollTo(0, 0)
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			var user_data = getUserData();
			self.setState({
				access_token: token,
				user_data: (user_data && user_data.user) ? user_data.user : {},
				credit_data: (user_data && user_data.credit) ? user_data.credit : '',
				is_loading_main: false
			});

			setTimeout(() => {
				self.getDataDashboard(api.dashboard);
				self.getDataCredit(api.credit_fudi, "FUTI");
			});
		}
	}

	getDataDashboard(API) {
		if (API) { } else { return false; }
		var self = this;
		self.setState({ isLoading: true });
		axios({
			method: 'GET',
			url: API,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				self.setState({
					user_data: (ress.data && ress.data.data && ress.data.data.user) ? ress.data.data.user : {},
					credit_data: (ress.data && ress.data.data && ress.data.data.credit) ? ress.data.data.credit : '',
				});
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
			self.setState({ isLoading: false });
		}).catch(function (e) {
			self.setState({ isLoading: false });
			//toast.error(e.message);
		});
	};

	getDataCredit(API, activeTab) {
		if (API) { } else { return false; }
		var self = this;
		self.setState({ isLoading: true });
		axios({
			method: 'GET',
			url: API,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				self.setState({
					creditListData: (ress.data.data && ress.data.data.rows && ress.data.data.rows.data) ? ress.data.data.rows.data : [],
					prev_page_url: (ress.data.data && ress.data.data.rows && ress.data.data.rows.prev_page_url) ? ress.data.data.rows.prev_page_url : '',
					next_page_url: (ress.data.data && ress.data.data.rows && ress.data.data.rows.next_page_url) ? ress.data.data.rows.next_page_url : '',
				});
				self.setState({ activeTab: activeTab });
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
				self.setState({ isLoading: false });
			}
		}).catch(function (e) {
			//toast.error(e.message);
			self.setState({ isLoading: false });
		});
	};

	getDataWithdrawalData(API) {
		if (API) { } else { return false; }
		var self = this;
		self.setState({ isLoading: true });
		self.setState({ is_show_withdrawal_records: true, is_show_withdrawal: false, withdrawalListData_load: true })
		axios({
			method: 'GET',
			url: API,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				self.setState({
					withdrawalListData: (ress.data.data && ress.data.data.rows && ress.data.data.rows.data) ? ress.data.data.rows.data : [],
					withdrawal_prev_page_url: (ress.data.data && ress.data.data.rows && ress.data.data.rows.prev_page_url) ? ress.data.data.rows.prev_page_url : '',
					withdrawal_next_page_url: (ress.data.data && ress.data.data.rows && ress.data.data.rows.next_page_url) ? ress.data.data.rows.next_page_url : '',
				});
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
			self.setState({ withdrawalListData_load: false })
			self.setState({ isLoading: false });
		}).catch(function (e) {
			self.setState({ withdrawalListData_load: false })
			self.setState({ isLoading: false });
			//toast.error(e.message);
		});
	};

	isTransferConfirm() {
		swal({
			title: strings["Confirm Transfer?"],
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((data) => {
			if (data) {
				this.isTransfer();
			}
		});
	}

	isTransfer() {
		var self = this;
		self.setState({ isLoading: true });
		axios({
			method: 'POST',
			url: api.transfer,
			data: {
				credit_name: self.state.transfer_credit_name,
				username: self.state.username,
				amount: self.state.amount,
			},
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				toast.success(ress.data.message);
				self.setState({ is_modal_transfer: !self.state.is_modal_transfer, transfer_credit_name: "USDT", amount: 0 });
				self.getDataDashboard(api.dashboard);
				self.getDataCredit(api.credit_fudi, "FUTI");
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
			self.setState({ isLoading: false });
		}).catch(function (e) {
			//toast.error(e.message);
			self.setState({ isLoading: false });
		});
	};

	isWithdrawalConfirm() {
		swal({
			title: strings["Confirm Withdrawal?"],
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((data) => {
			if (data) {
				this.isWithdrawal();
			}
		});
	}

	isWithdrawal() {
		var self = this;
		self.setState({ isLoading: true });
		axios({
			method: 'POST',
			url: api.withdrawal,
			data: {
				credit_name: self.state.withdrawal_credit_name,
				amount: self.state.withdrawal_amount,
			},
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				toast.success(ress.data.message);
				self.setState({ is_modal_withdrawal: !self.state.is_modal_withdrawal, withdrawal_credit_name: "FUTI", amount: 0 });
				self.getDataDashboard(api.dashboard);
				self.getDataCredit(api.credit_fudi, "FUTI");
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
			self.setState({ isLoading: false });
		}).catch(function (e) {
			//toast.error(e.message);
			self.setState({ isLoading: false });
		});
	};

	isConvert() {
		var self = this;
		self.setState({ isLoading: true });
		axios({
			method: 'POST',
			url: api.convert,
			data: {
				amount: self.state.convert_amount,
			},
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				toast.success(ress.data.message);
				self.setState({ is_modal_convert: !self.state.is_modal_convert });
				self.getDataDashboard(api.dashboard);
				self.getDataCredit(api.credit_fudi, "FUTI");
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
			self.setState({ isLoading: false });
		}).catch(function (e) {
			//toast.error(e.message);
			self.setState({ isLoading: false });
		});
	};

	placementReleaseClaim(credit_name) {
		var self = this;
		self.setState({ isLoading: true });
		axios({
			method: 'POST',
			url: api.placement_release_claim,
			data: {
				credit_name: credit_name,
			},
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				toast.success(ress.data.message);
				self.getDataDashboard(api.dashboard);
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
			self.setState({ isLoading: false });
		}).catch(function (e) {
			//toast.error(e.message);
			self.setState({ isLoading: false });
		});
	};

	avatarUpload(avatar_url) {
		const self = this;
		self.setState({ isLoading: true });
		axios({
			method: 'POST',
			url: api.profile_update_avatar,
			data: {
				avatar: avatar_url
			},
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				"Content-Type": "application/json"
			}
		}).then(function (ress) {
			// self.setState({ is_modal: false });
			if (ress.data && ress.data.error === 200) {
				toast.success(ress.data.message);
				window.location.reload();
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
			self.setState({ isLoading: false });
		}).catch(function (e) {
			self.setState({ isLoading: false });
			//toast.error(e.message);
		});
	}

	copyRefcode() {
		navigator.clipboard.writeText((this.state.user_data && this.state.user_data.username) ? this.state.user_data.username : '-')
		toast.success("Meta ID/Refcode Copied!");
	}

	copyWalletAddress() {
		navigator.clipboard.writeText((this.state.user_data && this.state.user_data.wallet_address) ? this.state.user_data.wallet_address : '-')
		toast.success("Wallet Address Copied!");
	}

	copyDepositWalletAddress(w) {
		navigator.clipboard.writeText(w);
		toast.success("Wallet Address Copied!");
	}

	isModalOpen() {
		let self = this;
		self.setState({ is_modal: true });

		setTimeout(() => {
			// self.loadModalData()
		}, 1000);

	}

	loadModalData() {
		let self = this;

		const subdomain = 'futurav3';
		var language = "en";
		if (self.state.lang == "EN") {
			language = "en";
		} else if (self.state.lang == "CN") {
			language = "ch";
		}
		const frame = document.getElementById('frame');

		// frame.src = `https://${subdomain}.readyplayer.me/avatar?frameApi`;
		frame.src = `https://${subdomain}.readyplayer.me/${language}/avatar?frameApi&clearCache`;

		window.addEventListener('message', subscribe);
		document.addEventListener('message', subscribe);

		function subscribe(event) {
			const json = parse(event);
			if (json?.source !== 'readyplayerme') {
				return;
			}

			if (json.eventName === 'v1.frame.ready') {
				frame.contentWindow.postMessage(
					JSON.stringify({
						target: 'readyplayerme',
						type: 'subscribe',
						eventName: 'v1.**'
					}),
					'*'
				);
			}

			if (json.eventName === 'v1.avatar.exported') {

				self.setState({ is_load_avatar: true });
				const params =
				{
					// Replace with the .glb URL for the desired avatar.
					model: json.data.url,
					// Type of portrait to render.
					scene: "fullbody-portrait-v1",
					// Optional: Pose. Default: T-pose.
					armature: "ArmatureTargetMale",
					// Optional: Facial expression. Default: Empty.
					"blendShapes": { "Wolf3D_Avatar": { "mouthSmile": 0.2 } }
				}
				const http = new XMLHttpRequest()
				http.open("POST", "https://render.readyplayer.me/render")
				http.setRequestHeader("Content-type", "application/json")
				http.send(JSON.stringify(params))
				http.onload = function () {
					self.avatarUpload(JSON.parse(http.responseText)["renders"][0]);
					self.setState({ is_load_avatar: false, random_key: self.state.random_key + 1 });
				}

			}
		}

		function parse(event) {
			try {
				return JSON.parse(event.data);
			} catch (error) {
				return null;
			}
		}

	}

	render() {
		return (
			<>
				<Helmet><title>{"FUTURA | Dashboard"}</title></Helmet>
				<div className="dashboard">
					<Header />

					<div className="container-fluid">
						<div className="row">
							<SideBarWeb />

							{!this.state.is_loading_main ?
								<>
									<div className="col">
										<br />
										<h1>{strings["Hello"]}!<br /> {(this.state.user_data && this.state.user_data.username) ? this.state.user_data.username : '-'}</h1>
										<div className="panel">
											<div className="row">
												<div className="col">
													<div className="pocket">
														<div className="px-2">
															<picture className="image_div">
																{/* <picture onClick={() => this.isModalOpen()} class="image_div"> */}
																<img className='btnimg' src={(this.state.user_data && this.state.user_data.avatar_url) ? this.state.user_data.avatar_url : "assets/images/avtr.png"} alt="" />
															</picture>
														</div>
														<div className="box">
															<div className="row">
																<div className="col-auto mb-2">
																	{strings["Meta ID/Refcode"]}:
																</div>
																<div className="col-auto mb-2">
																	{(this.state.user_data && this.state.user_data.username) ? this.state.user_data.username : '-'}
																</div>
																<div className="col mb-2 text-end">
																	<a onClick={() => this.copyRefcode()} >{strings["Copy ReferID"]}</a>
																</div>
															</div>
															<div className="row">
																<div className="col-auto mb-2">
																	{strings["Email Address"]}:
																</div>
																<div className="col-auto mb-2">
																	{(this.state.user_data && this.state.user_data.email_masked) ? this.state.user_data.email_masked : '-'}
																</div>
																<div className="col mb-2 text-end">
																	<a >{/*strings["Edit"]*/}</a>
																</div>
															</div>
															<div className="row">
																<div className="col-auto mb-2">
																	{strings["Wallet Address"]}:
																</div>
																<div className="col-auto mb-2">
																	{(this.state.user_data && this.state.user_data.wallet_address_masked) ? this.state.user_data.wallet_address_masked : '-'}
																</div>
																<div className="col mb-2 text-end">
																	<a onClick={() => this.copyWalletAddress()} >{strings["Copy Address"]}</a>
																</div>
															</div>
															<h3>{strings["Pocket Balance"]}</h3>
															<div className="row">
																<div className="col-auto">
																	<div className="row">
																		<div className="col-auto mb-1">
																			{strings["FUT"]}:&nbsp;&nbsp;
																		</div>
																		<div className="col-auto mb-1">
																			{number_format((this.state.credit_data && this.state.credit_data.fud) ? this.state.credit_data.fud : 0, 2, '.', ',')}
																		</div>
																	</div>
																	<div className="row green">
																		<div className="col-auto mb-1">
																			{strings["FUTI"]}:&nbsp;
																		</div>
																		<div className="col-auto mb-1">
																			{number_format((this.state.credit_data && this.state.credit_data.fudi) ? this.state.credit_data.fudi : 0, 2, '.', ',')}
																		</div>
																	</div>
																	<div className="row blue">
																		<div className="col-auto mb-1">
																			{strings["FUTE"]}:
																		</div>
																		<div className="col-auto mb-1">
																			{number_format((this.state.credit_data && this.state.credit_data.fude) ? this.state.credit_data.fude : 0, 2, '.', ',')}
																		</div>
																	</div>
																	<div className="row">
																		<div className="col-auto">
																			{strings["USDT"]}:
																		</div>
																		<div className="col-auto">
																			{number_format((this.state.credit_data && this.state.credit_data.usdt) ? this.state.credit_data.usdt : 0, 2, '.', ',')}
																		</div>
																	</div>
																</div>
																<div className="col pt-2 text-end">
																	<br /><br />
																	<a onClick={() => this.setState({ is_modal_convert: !this.state.is_modal_convert })}  className="me-1">{strings["Convert"]} </a>
																	<a onClick={() => this.setState({ is_modal_deposit: !this.state.is_modal_deposit })}  className="me-1">{strings["Deposit"]} </a><br />
																	<a onClick={() => this.setState({ is_modal_withdrawal: !this.state.is_modal_withdrawal, withdrawal_credit_name: "FUT" })}  className="me-1">{strings["Withdraw"]} </a>
																	<a onClick={() => this.setState({ is_modal_transfer: !this.state.is_modal_transfer })} >{strings["Transfer"]}</a>
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="col">
													<div className="placement">
														<div className="row align-items-center">
															<div className="col-6">
																<h3>{strings["My Placement Release"]}</h3>
															</div>
															<div className="col-6" style={{ textAlign: "right" }}>
																<Link to={"/nodes-report"} >{strings["Report"]}</Link>
															</div>
														</div>
														<div className="relese">
															<div className="row align-items-center">
																<div className="col-auto">
																	{strings["FUTI"]}:
																</div>
																<div className="col-auto">
																	{number_format((this.state.credit_data && this.state.credit_data.fudi_claim) ? this.state.credit_data.fudi_claim : 0, 2, '.', ',')}
																</div>
																<div className="col text-end">
																	{(this.state.credit_data && this.state.credit_data.fudi_claim) ?
																		<a onClick={() => this.placementReleaseClaim("FUTI")}  className="btn btn-primary rounded-pill">{strings["Claim"]}</a>
																		: null}
																</div>
															</div>
														</div>
														<div className="relese">
															<div className="row align-items-center">
																<div className="col-auto">
																	{strings["FUTE"]}:
																</div>
																<div className="col-auto">
																	{number_format((this.state.credit_data && this.state.credit_data.fude_claim) ? this.state.credit_data.fude_claim : 0, 2, '.', ',')}
																</div>
																<div className="col text-end">
																	{(this.state.credit_data && this.state.credit_data.fude_claim) ?
																		<a onClick={() => this.placementReleaseClaim("FUTE")}  className="btn btn-primary rounded-pill">{strings["Claim"]}</a>
																		: null}
																</div>
															</div>
														</div>
														<div className="balance">
															<div className="row">
																<div className="col-auto">
																	{strings["Vault Balance"]}:
																</div>
																<div className="col-auto">
																	{number_format((this.state.credit_data && this.state.credit_data.vault) ? this.state.credit_data.vault : 0, 2, '.', ',')}
																</div>
															</div>
															<div className="row">
																<div className="col-auto">
																	{strings["Total FUTI Release"]}:
																</div>
																<div className="col-auto">
																	{number_format((this.state.credit_data && this.state.credit_data.fudi_released) ? this.state.credit_data.fudi_released : 0, 2, '.', ',')}
																</div>
															</div>
															<div className="row">
																<div className="col-auto">
																	{strings["Total FUTE Release"]}:
																</div>
																<div className="col-auto">
																	{number_format((this.state.credit_data && this.state.credit_data.fude_released) ? this.state.credit_data.fude_released : 0, 2, '.', ',')}
																	({number_format((this.state.credit_data && this.state.credit_data.pending) ? this.state.credit_data.pending : 0, 2, '.', ',')})
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<nav className="mt-4 mb-2">
												<div className="nav nav-tabs nav-justified" id="nav-tab" role="tablist">
													<button onClick={() => this.getDataCredit(api.credit_fudi, "FUTI")} className={"nav-link " + ((this.state.activeTab == "FUTI") ? 'active' : '')} id="nav-fudi-tab" data-bs-toggle="tab" data-bs-target="#nav-fudi" type="button" role="tab" aria-controls="nav-fudi" aria-selected="true">{strings["FUTI"]}</button>
													<button onClick={() => this.getDataCredit(api.credit_fude, "FUTE")} className={"nav-link " + ((this.state.activeTab == "FUTE") ? 'active' : '')} id="nav-fude-tab" data-bs-toggle="tab" data-bs-target="#nav-fude" type="button" role="tab" aria-controls="nav-fude" aria-selected="false">{strings["FUTE"]}</button>
													<button onClick={() => this.getDataCredit(api.credit_fudep, "FUTEP")} className={"nav-link " + ((this.state.activeTab == "FUTEP") ? 'active' : '')} id="nav-fudep-tab" data-bs-toggle="tab" data-bs-target="#nav-fudep" type="button" role="tab" aria-controls="nav-fude" aria-selected="false">{strings["FUTEP"]}</button>
													<button onClick={() => this.getDataCredit(api.credit_usdt, "USDT")} className={"nav-link " + ((this.state.activeTab == "USDT") ? 'active' : '')} id="nav-usdt-tab" data-bs-toggle="tab" data-bs-target="#nav-usdt" type="button" role="tab" aria-controls="nav-usdt" aria-selected="true">{strings["USDT"]}</button>
													<button onClick={() => this.getDataCredit(api.credit_fud, "FUT")} className={"nav-link " + ((this.state.activeTab == "FUT") ? 'active' : '')} id="nav-fud-tab" data-bs-toggle="tab" data-bs-target="#nav-fud" type="button" role="tab" aria-controls="nav-fud" aria-selected="false">{strings["FUT"]}</button>
													<button onClick={() => this.getDataCredit(api.credit_pocket, "POCKET")} className={"nav-link " + ((this.state.activeTab == "POCKET") ? 'active' : '')} id="nav-pocket-tab" data-bs-toggle="tab" data-bs-target="#nav-pocket" type="button" role="tab" aria-controls="nav-pocket" aria-selected="false">{strings["POCKET"]}</button>
												</div>
											</nav>
											<div className="tab-content" id="nav-tabContent">
												<div className={"tab-pane fade " + ((this.state.activeTab == "FUTI") ? 'show active' : '')} id="nav-fudi" role="tabpanel" aria-labelledby="nav-fudi-tab" tabIndex="0">
													<div className="tbl-head">
														<div className="row align-items-center">
															<div className="col-4">
																{strings["Date/Time"]}
															</div>
															<div className="col-4">
																{strings["Description"]}
															</div>
															<div className="col-4">
																{strings["Amount"]}
															</div>
														</div>
													</div>
													<div className="tab-detail">
														{this.state.creditListData && this.state.creditListData.length != 0 ?
															<>
																{this.state.creditListData.map((item, i) =>
																	<div className="tbl-row">
																		<div className="row align-items-center">
																			<div className="col-4">
																				{moment(item.created_at).format("YYYY-MM-DD HH:MM:SS")}
																			</div>
																			<div className="col-4">
																				{item.description}
																			</div>
																			<div className="col-4">
																				{(item.amount) ? number_format(item.amount, 5, '.', ',') : '0'}
																			</div>
																		</div>
																	</div>
																)}
															</>
															: null}
													</div>
												</div>
												<div className={"tab-pane fade " + ((this.state.activeTab == "FUTE") ? 'show active' : '')} id="nav-fude" role="tabpanel" aria-labelledby="nav-fude-tab" tabIndex="0">
													<div className="tbl-head">
														<div className="row align-items-center">
															<div className="col-4">
																{strings["Date/Time"]}
															</div>
															<div className="col-4">
																{strings["Description"]}
															</div>
															<div className="col-4">
																{strings["Amount"]}
															</div>
														</div>
													</div>
													<div className="tab-detail">
														{this.state.creditListData && this.state.creditListData.length != 0 ?
															<>
																{this.state.creditListData.map((item, i) =>
																	<div className="tbl-row">
																		<div className="row align-items-center">
																			<div className="col-4">
																				{moment(item.created_at).format("YYYY-MM-DD HH:MM:SS")}
																			</div>
																			<div className="col-4">
																				{item.description}
																			</div>
																			<div className="col-4">
																				{(item.amount) ? number_format(item.amount, 5, '.', ',') : '0'}
																			</div>
																		</div>
																	</div>
																)}
															</>
															: null}
													</div>
												</div>
												<div className={"tab-pane fade " + ((this.state.activeTab == "FUTEP") ? 'show active' : '')} id="nav-fudep" role="tabpanel" aria-labelledby="nav-fudep-tab" tabIndex="0">
													<div className="tbl-head">
														<div className="row align-items-center">
															<div className="col-4">
																{strings["Date/Time"]}
															</div>
															<div className="col-4">
																{strings["Description"]}
															</div>
															<div className="col-4">
																{strings["Amount"]}
															</div>
														</div>
													</div>
													<div className="tab-detail">
														{this.state.creditListData && this.state.creditListData.length != 0 ?
															<>
																{this.state.creditListData.map((item, i) =>
																	<div className="tbl-row">
																		<div className="row align-items-center">
																			<div className="col-4">
																				{moment(item.created_at).format("YYYY-MM-DD HH:MM:SS")}
																			</div>
																			<div className="col-4">
																				{item.description}
																			</div>
																			<div className="col-4">
																				{(item.amount) ? number_format(item.amount, 5, '.', ',') : '0'}
																			</div>
																		</div>
																	</div>
																)}
															</>
															: null}
													</div>
												</div>
												<div className={"tab-pane fade " + ((this.state.activeTab == "USDT") ? 'show active' : '')} id="nav-usdt" role="tabpanel" aria-labelledby="nav-usdt-tab" tabIndex="0">
													<div className="tbl-head">
														<div className="row align-items-center">
															<div className="col-4">
																{strings["Date/Time"]}
															</div>
															<div className="col-4">
																{strings["Description"]}
															</div>
															<div className="col-4">
																{strings["Amount"]}
															</div>
														</div>
													</div>
													<div className="tab-detail">
														{this.state.creditListData && this.state.creditListData.length != 0 ?
															<>
																{this.state.creditListData.map((item, i) =>
																	<div className="tbl-row">
																		<div className="row align-items-center">
																			<div className="col-4">
																				{moment(item.created_at).format("YYYY-MM-DD HH:MM:SS")}
																			</div>
																			<div className="col-4">
																				{item.description}
																			</div>
																			<div className="col-4">
																				{(item.amount) ? number_format(item.amount, 5, '.', ',') : '0'}
																			</div>
																		</div>
																	</div>
																)}
															</>
															: null}
													</div>
												</div>
												<div className={"tab-pane fade " + ((this.state.activeTab == "FUT") ? 'show active' : '')} id="nav-fud" role="tabpanel" aria-labelledby="nav-fud-tab" tabIndex="0">
													<div className="tbl-head">
														<div className="row align-items-center">
															<div className="col-4">
																{strings["Date/Time"]}
															</div>
															<div className="col-4">
																{strings["Description"]}
															</div>
															<div className="col-4">
																{strings["Amount"]}
															</div>
														</div>
													</div>
													<div className="tab-detail">
														{this.state.creditListData && this.state.creditListData.length != 0 ?
															<>
																{this.state.creditListData.map((item, i) =>
																	<div className="tbl-row">
																		<div className="row align-items-center">
																			<div className="col-4">
																				{moment(item.created_at).format("YYYY-MM-DD HH:MM:SS")}
																			</div>
																			<div className="col-4">
																				{item.description}
																			</div>
																			<div className="col-4">
																				{(item.amount) ? number_format(item.amount, 5, '.', ',') : '0'}
																			</div>
																		</div>
																	</div>
																)}
															</>
															: null}
													</div>
												</div>
												<div className={"tab-pane fade " + ((this.state.activeTab == "POCKET") ? 'show active' : '')} id="nav-pocket" role="tabpanel" aria-labelledby="nav-pocket-tab" tabIndex="0">
													<div className="tbl-head">
														<div className="row align-items-center">
															<div className="col-3">
																{strings["Date/Time"]}
															</div>
															<div className="col-5">
																{strings["Description"]}
															</div>
															<div className="col-2">
																{strings["Amount"]}
															</div>
															<div className="col-2 text-center">
																{strings["Token Type"]}
															</div>
														</div>
													</div>
													<div className="tab-detail">
														{this.state.creditListData && this.state.creditListData.length != 0 ?
															<>
																{this.state.creditListData.map((item, i) =>
																	<div className="tbl-row">
																		<div className="row align-items-center">
																			<div className="col-3">
																				{moment(item.created_at).format("YYYY-MM-DD HH:MM:SS")}
																			</div>
																			<div className="col-5">
																				{item.description ?? "-"}
																			</div>
																			<div className="col-2">
																				{(item.amount) ? number_format(item.amount, 5, '.', ',') : '0'}
																			</div>
																			<div className="col-2 text-center">
																				{item.credit_type ?? "-"}
																			</div>
																		</div>
																	</div>
																)}
															</>
															: null}
													</div>
												</div>
											</div>
											<div className="mt-2 text-end">
												<a onClick={() => this.getDataCredit(this.state.prev_page_url, this.state.activeTab)}  className="next">{strings["Prev Page"]} </a>
												<a style={{ marginLeft: 10 }} onClick={() => this.getDataCredit(this.state.next_page_url, this.state.activeTab)}  className="next"> {strings["Next Page"]}</a>
											</div>
										</div>
									</div>
									<LatestNews />
								</>
								: null}

						</div>
					</div>

					<Modal
						isOpen={this.state.is_modal}
						style={{ content: { padding: 0, inset: 10 } }}
						onRequestClose={() => window.location.reload()}
					>
						<div className='dashboard modal-avatar'>
							{/* <div className='modal_close' onClick={() => this.setState({ is_modal: !this.state.is_modal })}>
								<img src={cancel} alt='' />
							</div> */}

							{this.state.is_load_avatar ?
								<div className='text-center'>
									<img src={loading} alt='' />
								</div>
								:
								<Iframe
									width="100%"
									height="100%"
									id="frame" class="frame"
									key={this.state.random_key}
									clearCache
									allow="geolocation *; camera *; microphone *; clipboard-read; clipboard-write;"
									display="block"
									position="relative"
								/>
							}
						</div>
					</Modal>

					<Modal
						isOpen={this.state.is_modal_transfer}
						style={{ content: { background: "transparent" } }}
					>
						<div className="modal-content modal-simple">
							<div className="modal-header">
								<h3>{strings["Transfer"]}</h3>
							</div>
							<div className="modal-body">
								<div className="mb-1">
									<Select
										className="form-control"
										value={this.state.transfer_credit_name}
										onChange={(e) => this.setState({ transfer_credit_name: e.target.value })}
									>
										<MenuItem value={"USDT"}>{strings["USDT"]}</MenuItem>
										<MenuItem value={"FUTI"}>{strings["FUTI"]}</MenuItem>
										<MenuItem value={"FUTE"}>{strings["FUTE"]}</MenuItem>
										<MenuItem value={"FUT"}>{strings["FUT"]}</MenuItem>
									</Select>
								</div>
								<div className='mb-3'> {this.state.errorMessage.credit_name && <div className='errorMessage'> {this.state.errorMessage.credit_name[0]} </div>}</div>
								<div className="mb-1">
									<input className="form-control" type="text" name='username' onChange={(e) => this.setState({ username: e.target.value })} value={this.state.username} placeholder={strings["Enter Username"]} />
								</div>
								<div className='mb-3'> {this.state.errorMessage.username && <div className='errorMessage'> {this.state.errorMessage.username[0]} </div>}</div>
								<div className="mb-1">
									<input className="form-control" type="text" name='amount' onChange={(e) => this.setState({ amount: e.target.value })} value={this.state.amount} placeholder={strings["Enter Amount"]} />
								</div>
								<div className='mb-3'> {this.state.errorMessage.amount && <div className='errorMessage'> {this.state.errorMessage.amount[0]} </div>}</div>
							</div>
							<div className="modal-footer">
								<button onClick={() => this.setState({ is_modal_transfer: !this.state.is_modal_transfer })} type="button" className="btn btn-warning rounded-pill" style={{ background: "#DE9E5E", marginRight: 10 }}>{strings["Cancel"]}</button>
								{this.state.isLoading ?
									<div type="button" className="btn btn-primary loader-btn rounded-pill">
										<div class="spinner-border loader-spinner" role="status">
											<span class="visually-hidden">{strings["Loading"]}...</span>
										</div>
										{strings["Loading"]}
									</div>
									:
									<button onClick={() => this.isTransferConfirm()} type="button" className="btn btn-primary rounded-pill">{strings["confirm"]}</button>
								}
							</div>
						</div>
					</Modal>

					<Modal
						isOpen={this.state.is_modal_withdrawal}
						style={{ content: { background: "transparent" } }}
					>
						<div className="modal-content modal-simple">
							<div className="modal-header">
								{/* <div className='modal_close' onClick={() => this.setState({ is_modal_withdrawal: !this.state.is_modal_withdrawal })}>
									<img src={cancel} alt='' />
								</div> */}
								<a className={(this.state.is_show_withdrawal ? 'active' : '')} onClick={() => this.setState({ is_show_withdrawal_records: false, is_show_withdrawal: true })} >
									<h3>{strings["Withdrawal"]}</h3>
								</a>
								<a className={(this.state.is_show_withdrawal_records ? 'active' : '')} onClick={() => this.getDataWithdrawalData(api.withdrawal)} >
									<h3>{strings["Records"]}</h3>
								</a>
							</div>
							{this.state.is_show_withdrawal ?
								<>
									<div className="modal-body">
										<div className="mb-1">
											<Select
												className="form-control"
												value={this.state.withdrawal_credit_name}
												onChange={(e) => this.setState({ withdrawal_credit_name: e.target.value })}
											>
												<MenuItem value={"FUT"}>{strings["FUT"]}</MenuItem>
												<MenuItem value={"USDT"}>{strings["USDT"]}</MenuItem>
											</Select>
										</div>
										<div className='mb-3'> {this.state.errorMessage.credit_name && <div className='errorMessage'> {this.state.errorMessage.credit_name[0]} </div>}</div>
										<div className="mb-1">
											<input className="form-control" type="text" name='amount' onChange={(e) => this.setState({ withdrawal_amount: e.target.value })} value={this.state.withdrawal_amount} placeholder={strings["Enter Amount"]} />
										</div>
										<div className='mb-3'> {this.state.errorMessage.amount && <div className='errorMessage'> {this.state.errorMessage.amount[0]} </div>}</div>
									</div>
									<div className="modal-footer">
										<button onClick={() => this.setState({ is_modal_withdrawal: !this.state.is_modal_withdrawal })} type="button" className="btn btn-warning rounded-pill" style={{ background: "#DE9E5E", marginRight: 10 }}>{strings["Cancel"]}</button>
										{this.state.isLoading ?
											<div type="button" className="btn btn-primary loader-btn rounded-pill">
												<div class="spinner-border loader-spinner" role="status">
													<span class="visually-hidden">{strings["Loading"]}...</span>
												</div>
												{strings["Loading"]}
											</div>
											:
											<button onClick={() => this.isWithdrawalConfirm()} type="button" className="btn btn-primary rounded-pill">{strings["confirm"]}</button>
										}
									</div>
								</>
								: null}
							{this.state.is_show_withdrawal_records ?
								<>
									<div className='modal-body'>
										<div className={"tab-pane fade show active"} id="nav-buy" role="tabpanel" aria-labelledby="nav-buy-tab" tabIndex="0">
											<div className="tbl-head">
												<div className="row align-items-center">
													<div className="col-3">
														{strings["ID"]}
													</div>
													<div className="col-7">
														{strings["Descriptions"]}
													</div>
													<div className="col-2">
														{strings["Status"]}
													</div>
												</div>
											</div>
											{this.state.withdrawalListData_load ?
												<div className="tbl-row">
													<div className="row align-items-center" style={{ justifyContent: "center" }}>
														{strings["Loading ..."]}
													</div>
												</div>
												:
												<>
													{this.state.withdrawalListData && this.state.withdrawalListData.length != 0 ?
														<>
															{this.state.withdrawalListData.map((item, i) =>
																<div className="tbl-row">
																	<div className="row align-items-center">
																		<div className="col-3">
																			{item.id_word ?? "-"}
																		</div>
																		<div className="col-7">
																			{strings["Amount withdrawal"]} {number_format((item.amount) ? item.amount : 0, 4, '.', ',')}
																		</div>
																		<div className={"col-2 text-end " + ((item.status_data && item.status_data.class) ? item.status_data.class : '')}>
																			{(item.status_data && item.status_data.name) ? item.status_data.name : "-"}
																		</div>
																	</div>
																</div>
															)}
														</>
														: null}
												</>
											}
										</div>
									</div>
									<div className="modal-footer">
										<button onClick={() => this.setState({ is_modal_withdrawal: !this.state.is_modal_withdrawal })} type="button" className="btn btn-warning rounded-pill" style={{ background: "#DE9E5E", marginRight: 10 }}>{strings["Cancel"]}</button>
									</div>
								</>
								: null}
						</div>
					</Modal>

					<Modal
						isOpen={this.state.is_modal_convert}
						style={{ content: { background: "transparent" } }}
					>
						<div className="modal-content modal-simple">
							<div className="modal-header">
								<a className={(this.state.is_show_withdrawal ? 'active' : '')} onClick={() => this.setState({ is_show_withdrawal_records: false, is_show_withdrawal: true })} >
									<h3>{strings["Convert"]}</h3>
								</a>
							</div>
							<>
								<div className="modal-body">
									<div className="mb-3">
										<div className="form-control radio" style={{ background: "#2d3e5c" }}>
											<label style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
												{strings["FUTI"]}: {number_format((this.state.credit_data && this.state.credit_data.fudi) ? this.state.credit_data.fudi : 0, 4, '.', ',')}
											</label>
										</div>
									</div>
									<div className="mb-3">
										<div className="form-control radio" style={{ background: "#2d3e5c" }}>
											<label style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
												{strings["FUTE"]}: {number_format((this.state.credit_data && this.state.credit_data.fude) ? this.state.credit_data.fude : 0, 4, '.', ',')}
											</label>
										</div>
									</div>

									<div className="mb-1">
										<input className="form-control" type="text" name='convert_amount' onChange={(e) => this.setState({ convert_amount: e.target.value })} value={this.state.convert_amount} placeholder={strings["Enter Convert Amount"]} />
									</div>
									<div className='mb-3'> {this.state.errorMessage.convert_amount && <div className='errorMessage'> {this.state.errorMessage.convert_amount[0]} </div>}</div>
								</div>
								<div className="modal-footer">
									<button onClick={() => this.setState({ is_modal_convert: !this.state.is_modal_convert })} type="button" className="btn btn-warning rounded-pill" style={{ background: "#DE9E5E", marginRight: 10 }}>{strings["Cancel"]}</button>

									{this.state.isLoading ?
										<div type="button" className="btn btn-primary loader-btn rounded-pill">
											<div class="spinner-border loader-spinner" role="status">
												<span class="visually-hidden">{strings["Loading"]}...</span>
											</div>
											{strings["Loading"]}
										</div>
										:
										<button onClick={() => this.isConvert()} type="button" className="btn btn-primary rounded-pill">
											{strings["confirm"]}
										</button>
									}
								</div>
							</>
						</div>
					</Modal>

					<Modal
						isOpen={this.state.is_modal_deposit}
						style={{ content: { background: "transparent" } }}
					>
						<div className="modal-content modal-simple">
							<div className="modal-header">
							</div>
							<>
								<div className="">

									<div className='row'>
										<div onClick={() => this.setState({ deposit_tab: "USDT" })} className='col menu_tabs' style={{ justifyContent: "flex-end" }}>
											<h6 className={(this.state.deposit_tab == "USDT") ? 'active_tab' : ""}>{strings["USDT"]}</h6>
										</div>
										<div onClick={() => this.setState({ deposit_tab: "FUT" })} className='col menu_tabs'>
											<h6 className={(this.state.deposit_tab == "FUT") ? 'active_tab' : ""}>{strings["FUT"]}</h6>
										</div>
									</div>

									<div className='mb-3 text-center'>
										<h4>{strings["Scan to Deposit"]}</h4>
									</div>

									<div className='mb-3 qr-code align-content-center justify-content-center d-flex'>
										<div style={{ height: "auto", width: 180, background: "#FFF", borderRadius: 10 }}>
											<QRCode
												size={180}
												fgColor='#000'
												style={{ padding: 20, width: 180, height: 180, maxWidth: "100%", width: "100%" }}
												value={(this.state.user_data && this.state.user_data.deposit_wallet) ? this.state.user_data.deposit_wallet : "0x7D22f45563845B6c826439B82c1DE3f1604f28dD"}
												viewBox={`0 0 180 180`}
											/>
										</div>
									</div>

									<div className="mb-3 text-center">
										<span>{(this.state.user_data && this.state.user_data.deposit_wallet) ? this.state.user_data.deposit_wallet : "0x7D22f45563845B6c826439B82c1DE3f1604f28dD"}</span>
									</div>

									<div className="mb-3 text-center">
										<button onClick={() => this.setState({ is_modal_deposit: !this.state.is_modal_deposit })} type="button" className="btn btn-warning rounded-pill mb-1" style={{ background: "#DE9E5E", marginRight: 10, }}>{strings["Cancel"]}</button>
										<button onClick={() => this.copyDepositWalletAddress((this.state.user_data && this.state.user_data.deposit_wallet) ? this.state.user_data.deposit_wallet : "0x7D22f45563845B6c826439B82c1DE3f1604f28dD")} type="button" className="btn btn-primary rounded-pill mb-1">{strings["Copy Address"]}</button>
									</div>

									<div className="mb-3">
										<h6>{strings["Terms & Conditions"]}</h6>
										<p>{strings["1. User only FSC-20 USDT tokens to make deposits. Non-Compatible tokens May result to the loss of your funds. Platform will not be responsible for such loss."]}</p>
										<p>{strings["2. Finds are accredited to your account only after 6 network confirmations."]}</p>
									</div>

								</div>
								<div className="modal-footer text-center">
								</div>
							</>
						</div>
					</Modal>

				</div>
				<SideBarMobile />
			</>
		);
	}
}

export default DashboardScreen;
