import React from "react";
import { NavLink } from "react-router-dom";
import strings from "../Language";

class HeaderAuth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: localStorage.getItem("language"),
            menu_show: false,
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
    }

    /**
     * Change Language
     * @param {string} obj 
     */
    handleChange = async (event) => {
        let obj = event.target.value;
        if (obj === "th") {
            strings.setLanguage('th');
            await localStorage.setItem("language", 'th');
            this.setState({ lang: 'th' });
            window.location.reload();
        }
        else {
            strings.setLanguage('en');
            await localStorage.setItem("language", 'en');
            this.setState({ lang: 'en' });
            window.location.reload();
        }
    };

    changeLanguage(lang, language_image) {
        if (lang) {
            strings.setLanguage(lang);
            localStorage.setItem("language", lang);
            localStorage.setItem("language_image", language_image);
            window.location.reload()
        }
    }

    render() {
        return (
            <>
                <nav className="navbar navbar-expand-lg fixed-top">
                    <div className="container-fluid">
                        <div className="d-flex">
                            <div className={"dropdown " + (this.state.menu_show ? 'show' : '')} type="button" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => this.setState({ menu_show: !this.state.menu_show })}>
                                <img src="assets/images/flag.png" alt="" />
                                <a  className="dropdown-toggle">UK</a>
                                <ul className={"dropdown-menu " + (this.state.menu_show ? 'show' : '')}>
                                    <li><a onClick={() => this.changeLanguage("EN", "assets/images/flag.png")} className="dropdown-item" href="#"><img src="assets/images/flag.png" alt="" /> EN</a></li>
                                    <li><a onClick={() => this.changeLanguage("CN", "assets/images/cn.png")} className="dropdown-item" href="#"><img src="assets/images/cn.png" alt="" /> CN</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </>
        );
    }
}

export default HeaderAuth;