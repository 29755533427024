import React from "react";
import _ from 'lodash';
import Modal from 'react-modal';
import Iframe from 'react-iframe';
import loading from './../../assets/images/loading.gif';
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { api } from "../../components/config/api";
import { Helmet } from "react-helmet";
import HeaderAuth from "../../components/Layout/HeaderAuth";
import strings from "../../components/Language";
import moment from "moment";
import Web3 from 'web3';
import { Network } from './../../components/Util'; 

class LoginScreen extends React.Component {
	constructor(props) {
		super(props);
		var url_string = window.location;
		var url = new URL(url_string);
		var refcode = url.searchParams.get("refcode");
		this.state = {
			lang: localStorage.getItem("language"),
			refcode: refcode,
			is_loading: false,
			is_load: false,
			is_modal: false,
			access_token: "",
			is_just_registered: false,

			contract: null,
			account: null,
			is_connected: false,
			net: "",
			auth_token: "",

			username: "",
			referral_username: "",
			errorMessage: [],

			account: null,
			report_data: [],
			next_page: null,
			previous_page: null,
			sponsor: "",

			is_send_otp_disabled: false,
			timer_text: "00:00",
			tm: 300,
		}
	}

	async componentDidMount() {
		var self = this;
		if (self.state.refcode) {
			await localStorage.setItem('refcode', self.state.refcode);
		}

		const user_token = await localStorage.getItem('user_token');
		if (user_token) {
			setTimeout(() => {
				self.setState({ access_token: user_token });
				window.location.replace('/dashboard');
			});
			return false;
		}
	}

	componentWillUnmount() {
		clearInterval(this.timer);
	}

	async doSign() {
		try {
			this.setState({ onpress: true })
			let that=this;
			const web3 = new Web3(window.ethereum);
			const accounts = await web3.eth.getAccounts();
            const string =  "FUTURA_PROJECT";
            new Promise((resolve, reject) => {
                web3.eth.personal.sign(string, accounts[0], "test").then(function (data) {
                    that.isConnect(data);
                    resolve(true);
                }).catch((e)=>{
					toast.error(e.toString());
					that.setState({ onpress: false,is_connected:false })
				});
            });
		}
		catch (e) {
			this.setState({ onpress: false })
			console.log(e);
		}
	}
	
	isConnect(signature) {
		var self = this;
		var json = {
			wallet_address: self.state.account,
			secret_key: signature,
		}
		self.setState({ errorMessage: [] });
		axios({
			method: 'POST',
			url: api.connect,
			data: json,
			headers: {
				'Content-Type': 'application/json;multipart/form-data;boundary=SOME_BOUNDARY',
				'Accept-Language': self.state.lang,
			}
		}).then(async function (ress) {
			self.setState({ onpress: false })
			if (ress.data.error == 202 || ress.data.error == '202') {
				if (ress.data.validation && ress.data.validation.length != 0) {
					self.setState({ errorMessage: ress.data.validation });
				} else {
					toast.error(ress.data.message);
				}
			} else {
				toast.success(ress.data.message);
				self.setState({ is_connected: true });
				if (ress.data && ress.data.data) {
					self.setState({ connectData: ress.data.data });
					self.setState({ access_token: (ress.data.data && ress.data.data.token) ? ress.data.data.token : "" });
					if (ress.data.data.need_to_verify) {
						self.setState({ is_connected: true })
						self.setState({ is_just_registered: true })
					} else if (ress.data.data.already_registered) {
						self.setState({ is_just_registered: false })
						await localStorage.setItem('user_token', (ress.data.data && ress.data.data.token) ? ress.data.data.token : "");
						window.location.reload()
					}
				}
			}
		}).catch(function (e) {
			self.setState({ onpress: false })
		});
	}

	startTimer() {
		var self = this;
		self.setState({ is_send_otp_disabled: true });
		this.timer = setInterval(() => {

			var counter = self.state.tm;
			if (counter > 0) {
				counter--;
				self.setState({ timer_text: moment.utc(counter * 1000).format('HH:mm:ss') });
				self.setState({ tm: counter });
				if (counter == 0) {
					self.setState({ is_send_otp_disabled: false });
					self.setState({ timer_text: "04:59" });
					self.setState({ tm: 300 });
					clearInterval(self.timer);
				}
			}
		}, 1000);
	}

	isSubmit() {
		var self = this;
		var json = {
			username: self.state.username,
			referral_username: (localStorage.getItem('refcode')) ? localStorage.getItem('refcode') : self.state.referral_username,
		}
		self.setState({ errorMessage: [] });
		axios({
			method: 'POST',
			url: api.verify_save,
			data: json,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json;multipart/form-data;boundary=SOME_BOUNDARY',
				'Accept-Language': self.state.lang,
			}
		}).then(async function (ress) {
			if (ress.data.error == 202 || ress.data.error == '202') {
				if (ress.data.validation && ress.data.validation.length != 0) {
					self.setState({ errorMessage: ress.data.validation });
				} else {
					toast.error(ress.data.message);
				}
			} else {
				toast.success(ress.data.message);
				await localStorage.setItem('user_token', (ress.data.data && ress.data.data.token) ? ress.data.data.token : '');
				toast.success(ress.data.message);
				setTimeout(function () {
					// self.isModalOpen()
					window.location.reload()
				}, 1000)
			}
		}).catch(function (e) {
		});
	}

	isModalOpen() {
		let self = this;
		self.setState({ is_modal: true });

		setTimeout(() => {
			self.loadModalData()
		}, 1000);

	}

	loadModalData() {
		let self = this;

		//const subdomain = 'futurav3';
		//const subdomain = 'futura-staging';

		const subdomain = process.env.AVATAR_URL;
		
		var language = "en";
		if (self.state.lang == "EN") {
			language = "en";
		} else if (self.state.lang == "CN") {
			language = "ch";
		}
		const frame = document.getElementById('frame');

		// frame.src = `https://${subdomain}.readyplayer.me/avatar?frameApi`;
		frame.src = `https://${subdomain}.readyplayer.me/${language}/avatar?frameApi&clearCache`;

		window.addEventListener('message', subscribe);
		document.addEventListener('message', subscribe);

		function subscribe(event) {
			const json = parse(event);
			if (json?.source !== 'readyplayerme') {
				return;
			}

			if (json.eventName === 'v1.frame.ready') {
				frame.contentWindow.postMessage(
					JSON.stringify({
						target: 'readyplayerme',
						type: 'subscribe',
						eventName: 'v1.**'
					}),
					'*'
				);
			}

			if (json.eventName === 'v1.avatar.exported') {

				self.setState({ is_load: true });
				const params =
				{
					// Replace with the .glb URL for the desired avatar.
					model: json.data.url,
					// Type of portrait to render.
					scene: "fullbody-portrait-v1",
					// Optional: Pose. Default: T-pose.
					armature: "ArmatureTargetMale",
					// Optional: Facial expression. Default: Empty.
					"blendShapes": { "Wolf3D_Avatar": { "mouthSmile": 0.2 } }
				}
				const http = new XMLHttpRequest()
				http.open("POST", "https://render.readyplayer.me/render")
				http.setRequestHeader("Content-type", "application/json")
				http.send(JSON.stringify(params))
				http.onload = function () {
					self.avatarUpload(JSON.parse(http.responseText)["renders"][0]);
					self.setState({ is_load: false, random_key: self.state.random_key + 1 });
				}

			}
		}

		function parse(event) {
			try {
				return JSON.parse(event.data);
			} catch (error) {
				return null;
			}
		}

	}

	avatarUpload(avatar_url) {
		const self = this;
		axios({
			method: 'POST',
			url: api.profile_update_avatar,
			data: {
				avatar: avatar_url
			},
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				"Content-Type": "application/json"
			}
		}).then(function (ress) {
			// self.setState({ is_modal: false });
			if (ress.data && ress.data.error === 200) {
				toast.success(ress.data.message);
				window.location.reload();
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
		}).catch(function (e) {
			//toast.error(e.message);
		});
	}

	async doConnect(){
		this.setState({ onpress: true })
		await window.ethereum.request({ method: 'eth_requestAccounts' });
		if (window.ethereum) {
            const web3 = new Web3(window.ethereum);
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
				this.setState({ account:accounts[0] });
                this.setState({ is_connected: true })
				this.doSign();
            }
            const networkId = await web3.eth.net.getId()
            if (networkId !== 663) {
                await window.ethereum.request({
                    method: "wallet_addEthereumChain",
                    params: [
                        {
                            ...Network["fsc"]
                        }
                    ]
                });
            }
           
			window.ethereum.on("accountsChanged", async accounts => {
				if (accounts.length > 0) {
					let { web3 } = window
					web3 = new Web3(web3.currentProvider);
					let accounts = await web3.eth.getAccounts();
					this.setState({ account: accounts[0] })
				}
				else {
					this.setState({ is_connected: false })
				}
			});
        }
		else{
			toast.warn("Open website in Dapp Browser")
			this.setState({ onpress: false })
		}
	}

	render() {
		return (
			<>
				<Helmet><title>{"FUTURA | Login"}</title></Helmet>
				<HeaderAuth />
				<div>
					<div className="wrapper">
						<div className="container-fluid">
							<div className="main">
								<div className="row">
									<div className="col">
										<div className="sign">
											<form>
												<figure>
													<img src="assets/images/logo.png" alt="" />
												</figure>
												<p>{strings["Meta-Account"]}</p>
												{
													this.state.is_connected ?
														this.state.onpress ?
															<a className="btn btn-primary rounded-pill text-white">
																<span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
																<span className="sr-only">{strings["Loading"]}...</span>
															</a>
															:
															<>
																
															</>
														:
														<button type="button" className="btn btn-primary rounded-pill web3 text-white" label={strings["Connect To Wallet"]} icon="hide" onClick={()=>this.doConnect()}>{strings["Connect To Wallet"]}</button>
												}

												{this.state.is_just_registered ?
													<>
														<div className="mb-1">
															<input className="form-control" type="text" name='username' onChange={(e) => this.setState({ username: e.target.value })} value={this.state.username} placeholder={strings["Create Meta ID"]} />
														</div>
														<div className='mb-3'> {this.state.errorMessage.username && <div className='errorMessage'> {this.state.errorMessage.username[0]} </div>}</div>
														{this.state.refcode ?
															<div className="mb-1">
																<input className="form-control" type="text" name='referral_username' onChange={(e) => this.setState({ referral_username: e.target.value })} value={this.state.refcode} placeholder={strings["Enter Refcode"]} readOnly disabled />
															</div>
															:
															<div className="mb-1">
																<input className="form-control" type="text" name='referral_username' onChange={(e) => this.setState({ referral_username: e.target.value })} value={this.state.referral_username} placeholder={strings["Enter Refcode"]} />
															</div>
														}
														<div className='mb-3'> {this.state.errorMessage.referral_username && <div className='errorMessage'> {this.state.errorMessage.referral_username[0]} </div>}</div>
														<button onClick={() => this.isSubmit()} type="button" className="btn btn-primary rounded-pill">{strings["Create Account"]}</button>
													</>
													: null}

											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Modal
					isOpen={this.state.is_modal}
					style={{ content: { padding: 0, inset: 10, marginTop: 50 } }}
				>
					<div className='dashboard modal-avatar'>
						{/*<div className='modal_close pt-3 pb-3'>
							<button onClick={() => window.location.reload()} type="button" className="btn btn-primary rounded-pill">{strings["Skip"]}</button>
						</div>*/}

						{this.state.is_load ?
							<div className='text-center'>
								<img src={loading} alt='' />
							</div>
							:
							<Iframe
								width="100%"
								height="100%"
								id="frame" class="frame"
								key={this.state.random_key}
								clearCache
								allow="geolocation *; camera *; microphone *; clipboard-read; clipboard-write;"
								display="block"
								position="relative"
							/>
						}
					</div>
				</Modal>
			</>
		);
	}
}

export default LoginScreen;
