import React from 'react';
import { Link } from 'react-router-dom'
import strings from '../../components/Language';
import { getToken, getUserData, number_format, storeUserData } from '../../components/Util';
import { Helmet } from 'react-helmet';

import { api } from '../../components/config/api';
import Modal from 'react-modal';
import Iframe from 'react-iframe';
import loading from './../../assets/images/loading.gif';
import cancel from './../../assets/images/cancel.png';

import Header from '../../components/Layout/Header';
import SideBarWeb from '../../components/Layout/SideBarWeb';
import SideBarMobile from '../../components/Layout/SideBarMobile';
import LatestNews from '../../components/Layout/LatestNews';
import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import moment from 'moment/moment';
import { MenuItem, Select } from '@mui/material';
import QRCode from "react-qr-code";


class NodesReportScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: localStorage.getItem("language"),
            isLoading: false,
            is_loading_main: false,
            is_load_avatar: false,
            access_token: "",
            is_modal: false,
            is_modal_transfer: false,
            is_modal_deposit: false,
            is_modal_withdrawal: false,
            is_modal_convert: false,
            is_show_withdrawal_records: false,
            is_show_withdrawal: true,
            random_key: 1,

            user_data: {},
            credit_data: {},
            activeTab: "FUTI",
            deposit_tab: "USDT",
            credit_name: "FUTI",

            transfer_credit_name: "USDT",
            username: "",
            amount: 0,

            withdrawal_credit_name: "FUT",
            withdrawal_amount: 0,

            convert_amount: 0,

            errorMessage: [],

            creditListData: [],
            prev_page_url: "",
            next_page_url: "",
            withdrawalListData_load: false,
            withdrawalListData: [],
            withdrawal_prev_page_url: "",
            withdrawal_next_page_url: "",

            avatar: "",

        }
    }

    async componentDidMount() {
        let self = this;
        self.setState({ is_loading_main: true });
        window.scrollTo(0, 0)
        var token = await getToken();
        if (token) {
            await storeUserData(token);
            var user_data = getUserData();
            self.setState({
                access_token: token,
                user_data: (user_data && user_data.user) ? user_data.user : {},
                credit_data: (user_data && user_data.credit) ? user_data.credit : '',
                is_loading_main: false
            });

            setTimeout(() => {
                self.getDataCredit(api.credit_fudi_released, "FUTI");
            });
        }
    }

    getDataCredit(API, activeTab) {
        if (API) { } else { return false; }
        var self = this;
        self.setState({ isLoading: true });
        axios({
            method: 'GET',
            url: API,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            if (ress.data && ress.data.error === 200) {
                self.setState({
                    creditListData: (ress.data.data && ress.data.data.rows && ress.data.data.rows.data) ? ress.data.data.rows.data : [],
                    prev_page_url: (ress.data.data && ress.data.data.rows && ress.data.data.rows.prev_page_url) ? ress.data.data.rows.prev_page_url : '',
                    next_page_url: (ress.data.data && ress.data.data.rows && ress.data.data.rows.next_page_url) ? ress.data.data.rows.next_page_url : '',
                });
                self.setState({ activeTab: activeTab });
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {
                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
                self.setState({ isLoading: false });
            }
        }).catch(function (e) {
            //toast.error(e.message);
            self.setState({ isLoading: false });
        });
    };

    render() {
        return (
            <>
                <Helmet><title>{"FUTURA | Dashboard"}</title></Helmet>
                <div className="dashboard">
                    <Header />

                    <div className="container-fluid">
                        <div className="row">
                            <SideBarWeb />

                            {!this.state.is_loading_main ?
                                <>
                                    <div className="col">
                                        <br />
                                        <h1>{strings["Hello"]}!<br /> {(this.state.user_data && this.state.user_data.username) ? this.state.user_data.username : '-'}</h1>
                                        <div className="panel">
                                            <div className="row align-items-center">
                                                    <Link to={"/dashboard"} >{strings["Back"]}</Link>
                                            </div>
                                            <nav className="mt-4 mb-2">
                                                <div className="nav nav-tabs nav-justified" id="nav-tab" role="tablist">
                                                    <button onClick={() => this.getDataCredit(api.credit_fudi_released, "FUTI")} className={"nav-link " + ((this.state.activeTab == "FUTI") ? 'active' : '')} id="nav-fudi-tab" data-bs-toggle="tab" data-bs-target="#nav-fudi" type="button" role="tab" aria-controls="nav-fudi" aria-selected="true">{strings["FUTI"]}</button>
                                                    <button onClick={() => this.getDataCredit(api.credit_fude_released, "FUTE")} className={"nav-link " + ((this.state.activeTab == "FUTE") ? 'active' : '')} id="nav-fude-tab" data-bs-toggle="tab" data-bs-target="#nav-fude" type="button" role="tab" aria-controls="nav-fude" aria-selected="false">{strings["FUTE"]}</button>
                                                </div>
                                            </nav>
                                            <div className="tab-content" id="nav-tabContent">
                                                <div className={"tab-pane fade " + ((this.state.activeTab == "FUTI") ? 'show active' : '')} id="nav-fudi" role="tabpanel" aria-labelledby="nav-fudi-tab" tabIndex="0">
                                                    <div className="tbl-head">
                                                        <div className="row align-items-center">
                                                            <div className="col-4">
                                                                {strings["Date/Time"]}
                                                            </div>
                                                            <div className="col-4">
                                                                {strings["Description"]}
                                                            </div>
                                                            <div className="col-4">
                                                                {strings["Amount"]}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-detail">
                                                        {this.state.creditListData && this.state.creditListData.length != 0 ?
                                                            <>
                                                                {this.state.creditListData.map((item, i) =>
                                                                    <div className="tbl-row">
                                                                        <div className="row align-items-center">
                                                                            <div className="col-4">
                                                                                {moment(item.created_at).format("YYYY-MM-DD HH:MM:SS")}
                                                                            </div>
                                                                            <div className="col-4">
                                                                                {item.description}
                                                                            </div>
                                                                            <div className="col-4">
                                                                                {(item.amount) ? number_format(item.amount, 5, '.', ',') : '0'}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                            : null}
                                                    </div>
                                                </div>
                                                <div className={"tab-pane fade " + ((this.state.activeTab == "FUTE") ? 'show active' : '')} id="nav-fude" role="tabpanel" aria-labelledby="nav-fude-tab" tabIndex="0">
                                                    <div className="tbl-head">
                                                        <div className="row align-items-center">
                                                            <div className="col-4">
                                                                {strings["Date/Time"]}
                                                            </div>
                                                            <div className="col-4">
                                                                {strings["Description"]}
                                                            </div>
                                                            <div className="col-4">
                                                                {strings["Amount"]}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="tab-detail">
                                                        {this.state.creditListData && this.state.creditListData.length != 0 ?
                                                            <>
                                                                {this.state.creditListData.map((item, i) =>
                                                                    <div className="tbl-row">
                                                                        <div className="row align-items-center">
                                                                            <div className="col-4">
                                                                                {moment(item.created_at).format("YYYY-MM-DD HH:MM:SS")}
                                                                            </div>
                                                                            <div className="col-4">
                                                                                {item.description}
                                                                            </div>
                                                                            <div className="col-4">
                                                                                {(item.amount) ? number_format(item.amount, 5, '.', ',') : '0'}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-2 text-end">
                                                <a onClick={() => this.getDataCredit(this.state.prev_page_url, this.state.activeTab)}  className="next">{strings["Prev Page"]} </a>
                                                <a style={{ marginLeft: 10 }} onClick={() => this.getDataCredit(this.state.next_page_url, this.state.activeTab)}  className="next"> {strings["Next Page"]}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <LatestNews />
                                </>
                                : null}

                        </div>
                    </div>

                </div>
                <SideBarMobile />
            </>
        );
    }
}

export default NodesReportScreen;