import React from "react";
import { NavLink } from "react-router-dom";
import strings from "../Language";
import Slider from "react-slick";
import { api } from "../config/api";
import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import moment from "moment";
class LatestNews extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: localStorage.getItem("language"),
            is_loading: false,
            access_token: "",
            data: [],
            menu_show: false,
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.getData(api.news)
    }

    /**
     * Change Language
     * @param {string} obj 
     */
    handleChange = async (event) => {
        let obj = event.target.value;
        if (obj === "th") {
            strings.setLanguage('th');
            await localStorage.setItem("language", 'th');
            this.setState({ lang: 'th' });
            window.location.reload();
        }
        else {
            strings.setLanguage('en');
            await localStorage.setItem("language", 'en');
            this.setState({ lang: 'en' });
            window.location.reload();
        }
    };

    getData(API) {
        if (API) { } else { return false; }
        var self = this;
        axios({
            method: 'GET',
            url: API,
            headers: {
                'Authorization': 'Bearer ' + self.state.access_token,
                'Content-Type': 'application/json'
            }
        }).then(function (ress) {
            if (ress.data && ress.data.error === 200) {
                self.setState({
                    data: (ress.data.data && ress.data.data.rows && ress.data.data.rows.data) ? ress.data.data.rows.data : [],
                    prev_page_url: (ress.data.data && ress.data.data.rows && ress.data.data.rows.prev_page_url) ? ress.data.data.rows.prev_page_url : '',
                    next_page_url: (ress.data.data && ress.data.data.rows && ress.data.data.rows.next_page_url) ? ress.data.data.rows.next_page_url : '',
                });
            } else {
                if (ress.data.validation && ress.data.validation.length !== 0) {
                    _.forEach(ress.data.validation, function (value) {
                        if (value[0]) {
                            toast.error(value[0]);
                        }
                    });
                } else {
                    toast.error(ress.data.message);
                }
            }
        }).catch(function (e) {
            //toast.error(e.message);
        });
    };

    render() {

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };


        return (
            <>
                <div className="col-md-3 news">
                    <div className="mb-4">
                        <h1>{strings["Latest"]}<br /> {strings["News/Updates"]}</h1>
                        <a  className="view">{strings["View All"]}</a>
                    </div>
                    <div className="d-none d-md-none d-lg-block news_list">
                        {this.state.data && this.state.data.length != 0 ?
                            <>
                                {this.state.data.map((item, i) =>
                                    <div  className="article" key={i*32} style={{backgroundImage:`url(${item.dashboard})`}}>
                                        <p style={{ backgroundColor: 'rgba(0,0,0,0.7)',padding: 10,borderRadius: 10 }}>
                                            {item.content.length > 100 ?
                                                `${item.content.substring(0, 100)}...` : item.content
                                            }
                                        </p>
                                        <span>{moment(item.created_at).format("YYYY-MM-DD")}</span><br/>
                                        <a target="_blank" href={item.eurl}>{"Read More"}</a>
                                    </div>
                                )}
                            </>
                            : null}
                    </div>
                    <div className="d-sm-block d-md-block d-lg-none">
                        {/* <div className="owl-carousel owl-theme card"> */}
                        <Slider {...settings}>
                            {this.state.data && this.state.data.length != 0 ?
                                <>
                                    {this.state.data.map((item, i) =>
                                        <div className="item" key={i*188}>
                                            <div className="article one" style={{backgroundImage:`url(${item.dashboard})`}}>
                                                <p style={{ lineHeight: 1.75,backgroundColor: 'rgba(0,0,0,0.7)',padding: 10,borderRadius: 10 }}>
                                                    {item.content.length > 100 ?
                                                        `${item.content.substring(0, 100)}...` : item.content
                                                    }
                                                </p>
                                                <span>{moment(item.created_at).format("YYYY-MM-DD")}</span><br/>
                                                <a target="_blank" href={item.eurl}>{"Read More"}</a>
                                            </div>
                                        </div>
                                    )}
                                </>
                                : null}
                        </Slider>
                        {/* </div> */}
                    </div>
                </div>
            </>
        );
    }
}

export default LatestNews;