import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import NotFound from './components/NotFound';
import LoginScreen from './pages/Auth/Login';

import DashboardScreen from './pages/Dashboard';
import FriendListScreen from './pages/FriendList';
import NodesScreen from './pages/Nodes';
import P2PListingScreen from './pages/P2PListing';
import SwapScreen from './pages/Swap';
import NodesReportScreen from './pages/Dashboard/NodesReport';

const App = (props) => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LoginScreen />} />
          <Route path="/dashboard" element={<DashboardScreen />} />
          <Route path="/friend-list" element={<FriendListScreen />} />
          <Route path="/nodes" element={<NodesScreen />} />
          <Route path="/p2p-listing" element={<P2PListingScreen />} />
          <Route path="/swap" element={<SwapScreen />} />
          <Route path="/nodes-report" element={<NodesReportScreen />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>

      <ToastContainer theme="dark" position={'top-center'}/>
    </>
  );
}

export default App;