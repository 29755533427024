const SiteURL =process.env.REACT_APP_SITE_URL;
const BaseURL = process.env.REACT_APP_API_URL;
const GuestURL = BaseURL + '/api/';
const UserURL = BaseURL + '/api/user/';

export const api = {
    MainURL:SiteURL,
    SiteURL: BaseURL,

    /** Guest API */
    app_version: GuestURL + "app/version/android",

    connect: GuestURL + "connect",
    news: GuestURL + "news",

    verify_save: UserURL + "verify/save",
    verify_send_otp: UserURL + "verify/send-otp",
    dashboard: UserURL + "dashboard",
    profile: UserURL + "profile",
    profile_update_avatar: UserURL + "profile/update-avatar",
    credit_fudi: UserURL + "credit/fudi",
    credit_fudi_released: UserURL + "credit/fudi-released",
    credit_fude: UserURL + "credit/fude",
    credit_fudep: UserURL + "credit/fudep",
    credit_fude_released: UserURL + "credit/fude-released",
    credit_vault: UserURL + "credit/vault",
    credit_pocket: UserURL + "credit/pocket",
    credit_fud: UserURL + "credit/fud",
    credit_usdt: UserURL + "credit/usdt",
    node_purchase: UserURL + "node/purchase",
    node_history: UserURL + "node/history",
    node_purchase: UserURL + "node/purchase",
    friends: UserURL + "friends",
    transfer: UserURL + "transfer",
    withdrawal: UserURL + "withdrawal",
    convert: UserURL + "convert",
    placement_release_claim: UserURL + "placement-release-claim",
    node_add:UserURL + "node-add",
}