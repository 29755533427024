const CN = {
    "confirm": "确认",
    "coming_soon": "即将推出",
    "processing": "交易已提交。请等待",
    "error": "错误",
    "success": "成功",
    "close": "关闭",
    "language": "语言",

    "Latest": "最新",
    "News/Updates": "新闻/更新",
    "View All": "查看全部",
    "Dashboard": "仪表主页",
    "NODES": "招募配套",
    "SWAP": "兑换场所",
    "FRIEND LIST": "好友列表",
    "P2P LISTING": "交易中心",
    "Logout": "账号退出",
    "We could not find": "寻找不到",
    "page": "页",
    "Meta-Account": "META帐户",
    "Loading": "加载中",
    "Create Meta ID": "创建META-ID",
    "Enter Your Email Address": "输入您的电子邮件地址",
    "Enter OTP": "输入验证码",
    "Request OTP": "请求验证码",
    "Enter Refcode": "输入推荐码",
    "Create Account": "创建帐户",
    "Hello": "你好",
    "Meta ID/Refcode": "META-ID/推荐ID",
    "Copy ReferID": "复制推荐ID",
    "Email Address": "电子邮件地址",
    "Edit": "编辑",
    "Wallet Address": "钱包地址",
    "Copy Address": "复制地址",
    "Pocket Balance": "口袋余额",
    "FUT": "FUT",
    "FUTI": "FUTI",
    "FUTE": "FUTE",
    "FUTEP": "FUTEP",
    "Deposit": "存款",
    "Withdraw": "提款",
    "Transfer": "转账",
    "My Placement Release": "节点释放",
    "Claim": "领取",
    "Vault Balance": "仓库额度",
    "POCKET": "口袋",
    "Date/Time": "日期/时间",
    "Description": "描述",
    "Amount": "金额",
    "Balance": "余额",
    "Prev Page": "上一页",
    "Next Page": "下一页",
    "View": "查看",
    "No Friends available": "暂无好友",
    "My All Members": "我的所有成员",
    "USDT": "USDT",
    "IN VAULT": "仓库额度",
    "PURCHASE NOW": "立即购买",
    "QUICK BUY": "快速购买",
    "QUICK SELL": "快速出售",
    "BUY": "购买",
    "SELL": "出售",
    "Quick Buy": "快速购买",
    "Quick Buy operates on a first-come, first-serve basis, utilizing available FUTI in the listing to make purchases swiftly.": "快速购买选项按照先到先得原则进行，通过列表中可用FUDI迅速进行购买。",
    "Request OTP": "请求验证码",
    "Exchange Now": "进行兑换",
    "MY BUY ORDER": "我的购买订单",
    "Quick Sell": "快速出售",
    "Quick Sell follows a first-come, first-serve approach, utilizing the total available FUTE in the listing to fulfill orders promptly to interested buyers.": "快速出售选项按照先到先得原则进行，通过列表中的可用FUDE迅速匹配买家的订单。",
    "Enter FUEDE Amount": "输入FUEDE金额",
    "Enter OTP": "输入验证码",
    "My Sell Order": "我的出售订单",
    "Seller": "卖家",
    "Amount of FUTI Listed for Sale": "FUDI递交销售数量",
    "Purchased Amount": "购买金额",
    "Amount On Listing": "递交金额",
    "Sold Amount": "已售金额",
    "Creat AD to Sell": "创建出售广告",
    "After each completed transaction, FUTI will instantly reflect in your pocket balance.": "每次完成交易后，FUDI将立即反映在您的钱包余额中。",
    "Enter FUTI Amount": "输入FUDI金额",
    "Creat AD to Buy": "创建购买广告",
    "After each completed transaction, FUTE will instantly reflect in your pocket balance.": "每次完成交易后，FUDE将立即反映在您的钱包余额中。",
    "SWAP": "兑换",
    "CROSS CHAIN": "跨链",
    "Choose Token": "选择代币",
    "Wallet Balance": "钱包余额",
    "Swap Now": "进行兑换",
    "Choose Chain": "选择链",
    "Skip": "跳",
    "Disconnect": "推出",
    "Are you sure?": "是否确定？",
    "Are you sure you want to logout?": "您确定要注销吗？",
    "Are you sure you want to disconnect?": "您确定要断开连接吗？",
    "Cancel": "取消",
    "Username": "用户名",
    "Enter Username": "输入用户名",
    "Enter Amount": "输入金额",
    "Transfer": "划转",
    "Withdrawal": "提款",
    "Total FUTI Release": "FUTI总释放",
    "Total FUTE Release": "FUTE总释放",
    "Records": "记录",
    "ID": "编号",
    "Descriptions": "描述",
    "Status": "地位",
    "Convert": "转换",
    "Pocket Balance": "口袋余额",
    "Deduct From Wallet": "从钱包中扣除",
    "Deduct From Pocket": "从口袋里扣除",
    "Amount withdrawal": "提取金额",
    "Loading ...": "装载 ...",
    "Copy Wallet address": "复制钱包地址",
    "Connect To Wallet":"链接钱包",
    "Scan to Deposit":"扫描存款",
    "Wallet Balance": "钱包余额",
    "Approve USDT": "USDT授权",
    "Approve FUT": "FUT授权",
    "Copy Address":"复制地址",
    "Terms & Conditions":"条款及条件",
    "1. User only FSC-20 USDT tokens to make deposits. Non-Compatible tokens May result to the loss of your funds. Platform will not be responsible for such loss.":"1. 仅使用 FSC-20 USDT 代币进行存款。 不兼容的代币可能会导致您的资金损失。 平台将不承担此类损失。",
    "2. Finds are accredited to your account only after 6 network confirmations.":"2. 只有经过 6 次网络确认后，搜索结果才会记入您的帐户。",
    "Confirm Transaction": "确认交易",
    "Confirm node purchase?": "确认购买节点？",
    "Report": "报告",
    "Back": "返回",
    "Token Type": "代币行类",
    "Read More":"阅读更多",
};
export default CN;