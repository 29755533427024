import React from 'react';
import strings from '../../components/Language';
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import { api } from '../../components/config/api';
import { USDT_ADDRESS, NODE_ABI, NODE_ADDRESS, FUT_ADDRESS, USDT_ABI, NODE_URL } from '../../components/config/contract';
import axios from 'axios';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { getToken, getUserData, number_format, storeUserData } from '../../components/Util';

import Header from '../../components/Layout/Header';
import SideBarWeb from '../../components/Layout/SideBarWeb';
import SideBarMobile from '../../components/Layout/SideBarMobile';
import LatestNews from '../../components/Layout/LatestNews';
import Modal from 'react-modal';
import { Network } from './../../components/Util'; 
import swal from 'sweetalert';
import Web3 from 'web3';

class NodesScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			is_connected:false,
			lang: localStorage.getItem("language"),
			isLoading: false,
			is_modal: false,
			access_token: "",
			user_data: {},
			credit_data: {},
			data: [],
			deduct_from: "Pocket",
			amount: 0,
			errorMessage: [],
			usdt_balance: 0,
			fut_balance: 0,
			is_usdt_approved: 0,
			is_fut_approved: 0,

		}
	}

	async componentDidMount() {
		let self = this;
		window.scrollTo(0, 0)
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			var user_data = getUserData();
			self.setState({
				access_token: token,
				user_data: (user_data && user_data.user) ? user_data.user : {},
				credit_data: (user_data && user_data.credit) ? user_data.credit : '',
			});
			setTimeout(() => {
				self.getData(api.node_purchase)
			});
		}
		await window.ethereum.request({ method: 'eth_requestAccounts' });
		if (window.ethereum) {
            const web3 = new Web3(window.ethereum || NODE_URL);
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
                this.setState({ account: accounts[0] })
                this.setState({ is_connected: true })
                
				//Check For USDT Approve
				const TokenContract = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
				const is_approve = await TokenContract.methods.allowance(accounts[0], NODE_ADDRESS).call();
				const balanceOfUSDT = await TokenContract.methods.balanceOf(accounts[0]).call();
				this.setState({ usdt_balance: web3.utils.fromWei(balanceOfUSDT, 'ether') })
				if (web3.utils.fromWei(is_approve, 'ether') >= 240) {
					this.setState({ is_usdt_approved: 240 })
				}

				//Check For FUT Approve
				const FUTTokenContract = new web3.eth.Contract(USDT_ABI, FUT_ADDRESS);
				const is_fut_approve = await FUTTokenContract.methods.allowance(accounts[0], NODE_ADDRESS).call();
				const balanceOfFUT = await TokenContract.methods.balanceOf(accounts[0]).call();
				this.setState({ fut_balance: web3.utils.fromWei(balanceOfFUT, 'ether') })
				if (web3.utils.fromWei(is_fut_approve, 'ether') >= 60) {
					this.setState({ is_fut_approved: 60 })
				}
			}
		}
	}

	getData(API) {
		if (API) { } else { return false; }
		var self = this;
		axios({
			method: 'GET',
			url: API,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				self.setState({ data: (ress.data.data && ress.data.data.packages) ? ress.data.data.packages : [] });
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
		}).catch(function (e) {
			//toast.error(e.message);
		});
	}

	isModalOpen(package_id) {
		this.setState({
			package_id: package_id,
			is_modal: !this.state.is_modal
		})
	}

	packagePurchaseConfirm(package_id) {
		swal({
			title: strings["Confirm Transaction"],
			text: strings["Confirm node purchase?"],
			icon: "warning",
			buttons: true,
			dangerMode: true,
		}).then((data) => {
			if (data) {
				this.packagePurchase(package_id);
			}
		});
	}

	packagePurchase(package_id) {
		var self = this;
		if (!package_id) {
			toast.error("Package id not found!");
			return false;
		}
		self.setState({ isLoading: true });
		var json = {
			package_id: package_id,
		}
		axios({
			method: 'POST',
			url: api.node_purchase,
			data: json,
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			if (ress.data && ress.data.error === 200) {
				toast.success(ress.data.message);
				self.getData(api.node_purchase);
				self.setState({ is_modal: !self.state.is_modal, deduct_from: "Wallet" });
			} else {
				if (ress.data.validation && ress.data.validation.length !== 0) {
					_.forEach(ress.data.validation, function (value) {
						if (value[0]) {
							toast.error(value[0]);
						}
					});
				} else {
					toast.error(ress.data.message);
				}
			}
			self.setState({ isLoading: false });
		}).catch(function (e) {
			self.setState({ isLoading: false });
			//toast.error(e.message);
		});
	}

	async doConnect(){
		this.setState({ onpress: true })
		await window.ethereum.request({ method: 'eth_requestAccounts' });
		if (window.ethereum) {
            const web3 = new Web3(window.ethereum);
            let accounts = await web3.eth.getAccounts();
            if (accounts.length) {
				this.setState({ account:accounts[0] });
                this.setState({ is_connected: true })
				this.doSign();
            }
            const networkId = await web3.eth.net.getId()
            if (networkId !== 663) {
                await window.ethereum.request({
                    method: "wallet_addEthereumChain",
                    params: [
                        {
                            ...Network["fsc"]
                        }
                    ]
                });
            }
           
			window.ethereum.on("accountsChanged", async accounts => {
				if (accounts.length > 0) {
					let { web3 } = window
					web3 = new Web3(web3.currentProvider);
					let accounts = await web3.eth.getAccounts();
					this.setState({ account: accounts[0] })
				}
				else {
					this.setState({ is_connected: false })
				}
			});
        }
		else{
			toast.warn("Open website in Dapp Browser")
			this.setState({ onpress: false })
		}
	}

	/** 
	 * Approve USDT 
	 */
	async approveUSDT() {
		if (this.state.is_connected) {
			this.setState({ onpress: 1 })
			let that = this;
			const web3 = new Web3(window.ethereum);
			const Token = new web3.eth.Contract(USDT_ABI, USDT_ADDRESS);
			const resolveAfter3Sec = new Promise((resolve, reject) => {
				Token.methods.approve(NODE_ADDRESS, web3.utils.toWei('1000', 'ether')).send(
					{
						from: this.state.account,
					})
					.on('transactionHash', function (hash) {
					})
					.on('receipt', function (receipt) {
						that.setState({ onpress: 0, is_usdt_approved: 1 })
						resolve(true)
					})
					.on('confirmation', function (confirmationNumber, receipt) {
					})
					.on('error', function (error, receipt) {
						toast.error(error.message);
						that.setState({ onpress: 0 })
						reject(error.message)
					});
			});
			toast.promise(
				resolveAfter3Sec,
					{
						pending: strings.processing,
						success: strings.approved,
						error: strings.rejected,
					}, {
					position: "top-center",
					autoClose: !1,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: 0,
				}
			)
		}
		else {
			toast.error("Connect Wallet");
		}
	}

	/** 
	 * Approve FUT 
	 */
	async approveFUT() {
		if (this.state.is_connected) {
			this.setState({ onpress: 1 })
			let that = this;
			const web3 = new Web3(window.ethereum);
			const Token = new web3.eth.Contract(USDT_ABI, FUT_ADDRESS);
			const resolveAfter3Sec = new Promise((resolve, reject) => {
				Token.methods.approve(NODE_ADDRESS, web3.utils.toWei('1000', 'ether')).send(
					{
						from: this.state.account,
					})
					.on('transactionHash', function (hash) {
					})
					.on('receipt', function (receipt) {
						that.setState({ onpress: 0, is_fut_approved: 1 })
						resolve(true)
					})
					.on('confirmation', function (confirmationNumber, receipt) {
					})
					.on('error', function (error, receipt) {
						toast.error(error.message);
						that.setState({ onpress: 0 })
						reject(error.message)
					});
			});
			toast.promise(
				resolveAfter3Sec,
					{
						pending: strings.processing,
						success: strings.approved,
						error: strings.rejected,
					}, {
					position: "top-center",
					autoClose: !1,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: 0,
				}
			)
		}
		else {
			toast.error("Connect Wallet");
		}
	}

	async doPurchase() {
		if (this.state.is_connected) {
            this.setState({ onpress: 1 })
            let that = this;
            const web3 = new Web3(window.ethereum);
            const Staking = new web3.eth.Contract(NODE_ABI, NODE_ADDRESS);
			const resolveAfter3Sec = new Promise((resolve, reject) => {
				Staking.methods.buy().send(
					{
						from: this.state.account,
					})
					.on('transactionHash', function (hash) {
					})
					.on('receipt', function (receipt) {
						that.setState({ onpress: 0 })
						that.addNode();
						resolve(true);
					})
					.on('confirmation', function (confirmationNumber, receipt) {
					})
					.on('error', function (error, receipt) {
						toast.error(error.message);
						that.setState({ onpress: 0 })
						reject(error.message)
					});
			});
			toast.promise(
				resolveAfter3Sec,
				{
					pending: strings.processing,
					success: strings.staked,
					error: strings.rejected,
				}, 
				{
					position: "top-center",
					autoClose: !1,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: true,
					progress: 0,
				}
			)
        }
        else {
            toast.error("Connect Wallet");
        }
	}

	addNode() {
		var self = this;
		self.setState({ isLoading: true });
		axios({
			method: 'POST',
			url: api.node_add,
			data: {
				wallet:this.state.account
			},
			headers: {
				'Authorization': 'Bearer ' + self.state.access_token,
				'Content-Type': 'application/json'
			}
		}).then(function (ress) {
			self.setState({ isLoading: false });
		}).catch(function (e) {
			self.setState({ isLoading: false });
		});
	}

	render() {

		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: (this.state.data && this.state.data.length < 3) ? this.state.data.length : 3,
			slidesToScroll: 1
		};

		return (
			<>
				<Helmet><title>{"FUTURA | Nodes"}</title></Helmet>
				<div className="dashboard">
					<Header />

					<div className="container-fluid">
						<div className="row">
							<SideBarWeb />

							<>
								<div className="col content">
									<br />
									<h1>{strings["Hello"]}!<br /> {(this.state.user_data && this.state.user_data.username) ? this.state.user_data.username : '-'}</h1>
									<div className="panel nodes">
										{/* <div className="owl-carousel owl-theme node"> */}
										{this.state.data && this.state.data.length != 0 ?
											<Slider {...settings}>
												{this.state.data.map((item, i) =>
													<div className="item" key={i * 234}>
														<div className="found">
															<div className="shade">
																<h1>{item.name}</h1>
																<div className="hilite">
																	<div className="row gx-3">
																		<div className="col-3">
																			{item.usdt}
																		</div>
																		<div className="col-3">
																			{strings["USDT"]}
																		</div>
																		<div className="col-6">
																			{item.fud} {strings["FUT"]}
																		</div>
																	</div>
																</div>
																<div className="hilite">
																	<div className="row gx-3">
																		<div className="col-3">
																			{item.vault}
																		</div>
																		<div className="col-3">
																			{strings["FUT"]}
																		</div>
																		<div className="col-6">
																			{strings["IN VAULT"]}
																		</div>
																	</div>
																</div>
															</div>
														</div>
														<div className="purchase" onClick={() => this.isModalOpen(item.id)} style={{ cursor: "pointer" }}>
															<div className="shade">
																<h1>{strings["PURCHASE NOW"]}</h1>
																{/* <h1>PURCHASE NOW <i className="fa fa-lock"></i></h1> */}
															</div>
														</div>
													</div>
												)}
											</Slider>
											: null}
										{/* </div> */}
									</div>
								</div>
							</>

							<LatestNews />

							<Modal
								isOpen={this.state.is_modal}
								style={{ content: { background: "transparent" } }}
							>
								<div className="modal-content modal-simple">
									<div className="modal-header">
										<h3>{strings["Pocket Balance"]}</h3>
									</div>
									<div className="modal-body">

										<div className="mb-3">
											<div className="form-control radio" style={{ background: "#2d3e5c" }}>
												<label style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
													{strings["Deduct From Pocket"]}
													<input onClick={() => this.setState({ deduct_from: "Pocket" })} type="radio" name='deduct_from' value="Pocket" checked={(this.state.deduct_from == "Pocket") ? true : false} />
												</label>
											</div>
										</div>

										<div className="mb-3">
											<div className="form-control radio" style={{ background: "#2d3e5c" }}>
												<label style={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}>
												 🔒 {strings["Deduct From Wallet"]}
													<input onClick={() => this.setState({ deduct_from: "Pocket" })} type="radio" name='deduct_from' value="Pocket" checked={(this.state.deduct_from == "Wallet") ? true : false} />
												</label>
											</div>
										</div>
										<div className='mb-3'> {this.state.errorMessage.deduct_from && <div className='errorMessage'> {this.state.errorMessage.deduct_from[0]} </div>}</div>
										{
											this.state.deduct_from == "Pocket" ?
												<>
													<div className="balance">
														<div className="row mb-2">
															<div className="col-auto">
																{strings["Pocket Balance"]}:
															</div>
														</div>
														<div className="row">
															<div className="col-auto">
																{number_format((this.state.credit_data && this.state.credit_data.usdt) ? this.state.credit_data.usdt : 0, 4, '.', ',')}
															</div>
															<div className="col-auto">
																{strings["USDT"]}
															</div>
														</div>
														<div className="row">
															<div className="col-auto">
																{number_format((this.state.credit_data && this.state.credit_data.fud) ? this.state.credit_data.fud : 0, 4, '.', ',')}
															</div>
															<div className="col-auto">
																{strings["FUT"]}
															</div>
														</div>
													</div>
												</>
												:
												<div className="balance">
													<div className="row mb-2">
														<div className="col-auto">
															{strings["Wallet Balance"]}:
														</div>
													</div>
													<div className="row">
														<div className="col-auto">
															{number_format(this.state.usdt_balance, 4, '.', ',')}
														</div>
														<div className="col-auto">
															{strings["USDT"]}
														</div>
													</div>
													<div className="row">
														<div className="col-auto">
															{number_format(this.state.fut_balance, 4, '.', ',')}
														</div>
														<div className="col-auto">
															{strings["FUT"]}
														</div>
													</div>
												</div>
										}

									</div>
									{
											this.state.deduct_from == "Pocket" ?
											<>
												<div className="modal-footer">
													<button onClick={() => this.setState({ is_modal: !this.state.is_modal })} type="button" className="btn btn-warning rounded-pill" style={{ background: "#DE9E5E", marginRight: 10 }}>{strings["Cancel"]}</button>
													{this.state.isLoading ?
														<div type="button" className="btn btn-primary loader-btn rounded-pill">
															<div className="spinner-border loader-spinner" role="status">
																<span className="visually-hidden">{strings["Loading"]}...</span>
															</div>
															{strings["Loading"]}
														</div>
														:
														<button onClick={() => this.packagePurchaseConfirm(this.state.package_id)} type="button" className="btn btn-primary rounded-pill">{strings["confirm"]}</button>
													}
												</div>
											</>
											:
											<div className="modal-footer mt-2">
												<div className=''>
													<div className='mt-2 d-flex justify-content-center'>
														{
															this.state.is_usdt_approved>0 || true ?
																<>
																	{this.state.isLoading ?
																		<div type="button" className="btn btn-primary loader-btn rounded-pill">
																			<div className="spinner-border loader-spinner" role="status">
																				<span className="visually-hidden">{strings["Loading"]}...</span>
																			</div>
																			{strings["Loading"]}
																		</div>
																		:
																		<button onClick={() => this.approveUSDT()} type="button" className="btn btn-primary rounded-pill" style={{ marginRight: 10 }}>{strings["Approve USDT"]}</button>
																	}
																</>
																:
																null
														}
														{
															this.state.is_fut_approved>0 || true?
																<>
																	{this.state.isLoading ?
																		<div type="button" className="btn btn-primary loader-btn rounded-pill">
																			<div className="spinner-border loader-spinner" role="status">
																				<span className="visually-hidden">{strings["Loading"]}...</span>
																			</div>
																			{strings["Loading"]}
																		</div>
																		:
																		<button onClick={() => this.approveFUT(this.state.package_id)} type="button" className="btn btn-primary rounded-pill" style={{ marginRight: 10 }}>{strings["Approve FUT"]}</button>
																	}
																</>
																:
																null
														}
													</div>
													<div className='mt-2 d-flex justify-content-center'>
														<button onClick={() => this.setState({ is_modal: !this.state.is_modal })} type="button" className="btn btn-warning rounded-pill" style={{ background: "#DE9E5E", marginRight: 10 }}>{strings["Cancel"]}</button>

														{this.state.isLoading ?
															<div type="button" className="btn btn-primary loader-btn rounded-pill">
																<div className="spinner-border loader-spinner" role="status">
																	<span className="visually-hidden">{strings["Loading"]}...</span>
																</div>
																{strings["Loading"]}
															</div>
															:
															<button onClick={() => this.doPurchase()} type="button" className="btn btn-primary rounded-pill">{strings["confirm"]}</button>
														}
													</div>
												</div>
											</div>
									}

								</div>
							</Modal>

						</div>
					</div>

				</div >
				<SideBarMobile />
			</>
		);
	}
}

export default NodesScreen;
