import React from 'react';
import { Link } from 'react-router-dom'
import strings from '../../components/Language';
import { Helmet } from 'react-helmet';
import Slider from "react-slick";
import { api } from '../../components/config/api';
import Modal from '@mui/material/Modal';

import Header from '../../components/Layout/Header';
import SideBarWeb from '../../components/Layout/SideBarWeb';
import SideBarMobile from '../../components/Layout/SideBarMobile';
import LatestNews from '../../components/Layout/LatestNews';
import { getToken, getUserData, storeUserData } from '../../components/Util';

class P2PListingScreen extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			lang: localStorage.getItem("language"),
			activeTab: "nav-quickbuy",
			modal_myBuyOrder: false,
			modal_mySellOrder: false,
			modal_createSell: false,
			modal_createBuy: false,
			user_data: {},
			credit_data: {},
		}
	}

	async componentDidMount() {
		let self = this;
		window.scrollTo(0, 0)
		var token = await getToken();
		if (token) {
			await storeUserData(token);
			var user_data = getUserData();
			self.setState({
				access_token: token,
				user_data: (user_data && user_data.user) ? user_data.user : {},
				credit_data: (user_data && user_data.credit) ? user_data.credit : '',
			});
		}
	}

	render() {

		const settings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 1
		};

		return (
			<>
				<Helmet><title>{"FUTURA | P2P Listing"}</title></Helmet>
				<div className="dashboard">
					<Header />

					<div className="container-fluid">
						<div className="row">
							<SideBarWeb />

							<>
								<div className="col content">
									<br />
									<h1>{strings["Hello"]}!<br /> {(this.state.user_data && this.state.user_data.username) ? this.state.user_data.username : '-'}</h1>
									<div className="panel">
										<h1>{strings["P2P LISTING"]} is {strings["coming_soon"]}</h1>
										{/*<div className="row gx-3">
											<div className="col-6 col-md-2">
												<div className="fudi">
													<img src="assets/images/fude.png" className="pic" alt="" /> {strings["FUTE"]}:0.0000
												</div>
											</div>
											<div className="col-6 col-md-2 order-md-2">
												<div className="dropdown fudi float-end">
													<a className="dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
														<img src="assets/images/fudi.png" className="pic" alt="" />	{strings["FUTI"]}
													</a>
												</div>
											</div>
											<div className="col listing">
												<div className="nav nav-tabs nav-justified" id="nav-tab" role="tablist">
													<button onClick={() => this.setState({ activeTab: "nav-quickbuy" })} className={"nav-link " + ((this.state.activeTab == "nav-quickbuy") ? 'active' : '')} id="nav-quickbuy-tab" data-bs-toggle="tab" data-bs-target="#nav-quickbuy" type="button" role="tab" aria-controls="nav-quickbuy" aria-selected="true">{strings["QUICK BUY"]}</button>
													<button onClick={() => this.setState({ activeTab: "nav-quicksell" })} className={"nav-link " + ((this.state.activeTab == "nav-quicksell") ? 'active' : '')} id="nav-quicksell-tab" data-bs-toggle="tab" data-bs-target="#nav-quicksell" type="button" role="tab" aria-controls="nav-quicksell" aria-selected="false">{strings["QUICK SELL"]}</button>
													<button onClick={() => this.setState({ activeTab: "nav-buy" })} className={"nav-link " + ((this.state.activeTab == "nav-buy") ? 'active' : '')} id="nav-buy-tab" data-bs-toggle="tab" data-bs-target="#nav-buy" type="button" role="tab" aria-controls="nav-buy" aria-selected="false">{strings["BUY"]}</button>
													<button onClick={() => this.setState({ activeTab: "nav-sell" })} className={"nav-link " + ((this.state.activeTab == "nav-sell") ? 'active' : '')} id="nav-sell-tab" data-bs-toggle="tab" data-bs-target="#nav-sell" type="button" role="tab" aria-controls="nav-sell" aria-selected="false">{strings["SELL"]}</button>
												</div>
											</div>
										</div>
										<div className="tab-content" id="nav-tabContent">
											<div className={"tab-pane fade " + ((this.state.activeTab == "nav-quickbuy") ? 'show active' : '')} id="nav-quickbuy" role="tabpanel" aria-labelledby="nav-quickbuy-tab" tabIndex="0">
												<div className="swapbox my-4 mx-auto">
													<h3><u>{strings["Quick Buy"]}</u></h3>
													<p>{strings["Quick Buy operates on a first-come, first-serve basis, utilizing available FUTI in the listing to make purchases swiftly."]}</p>
													<div className="mt-5 mb-3">
														<input className="form-control" type="text" placeholder={strings["Enter FUEDE Amount"]} />
													</div>
													<div className="text-center">
														<button type="button" className="btn btn-primary btn-swap rounded-pill px-5">{strings["Exchange Now"]}</button>
													</div>
												</div>
												<div className="foot">
													<a href='javascript:void(0)' onClick={() => this.setState({ modal_myBuyOrder: true })} data-bs-toggle="modal" data-bs-target="#myBuyOrder">{strings["MY BUY ORDER"]}</a>
												</div>
											</div>
											<div className={"tab-pane fade " + ((this.state.activeTab == "nav-quicksell") ? 'show active' : '')} id="nav-quicksell" role="tabpanel" aria-labelledby="nav-quicksell-tab" tabIndex="0">
												<div className="swapbox my-4 mx-auto">
													<h3><u>{strings["Quick Sell"]}</u></h3>
													<p>{strings["Quick Sell follows a first-come, first-serve approach, utilizing the total available FUTE in the listing to fulfill orders promptly to interested buyers."]}</p>
													<div className="mt-5 mb-3">
														<input className="form-control" type="text" placeholder={strings["Enter FUEDE Amount"]} />
													</div>
													<div className="text-center">
														<button type="button" className="btn btn-primary btn-swap rounded-pill px-5">{strings["Exchange Now"]}</button>
													</div>
												</div>
												<div className="foot">
													<a href='javascript:void(0)' onClick={() => this.setState({ modal_mySellOrder: true })} data-bs-toggle="modal" data-bs-target="#mySellOrder">{strings["My Sell Order"]}</a>
												</div>
											</div>
											<div className={"tab-pane fade " + ((this.state.activeTab == "nav-buy") ? 'show active' : '')} id="nav-buy" role="tabpanel" aria-labelledby="nav-buy-tab" tabIndex="0">
												<div className="tbl-head">
													<div className="row align-items-center">
														<div className="col-3">
															{strings["Seller"]}
														</div>
														<div className="col-7">
															{strings["Amount of FUTI Listed for Sale"]}
														</div>
														<div className="col-2">

														</div>
													</div>
												</div>
												<div className="foot">
													<div className="row align-items-center">
														<div className="col-8 col-md-6">
															<a href='javascript:void(0)' onClick={() => this.setState({ modal_createSell: true })} data-bs-toggle="modal" data-bs-target="#createSell">CREATE AD TO SELL</a> <img src="assets/images/louder.svg" className="mx-2" alt="" />
															<a href='javascript:void(0)' onClick={() => this.setState({ modal_myBuyOrder: true })} data-bs-toggle="modal" data-bs-target="#myBuyOrder">MY BUY ORDER</a>
														</div>
														<div className="col-4 col-md-6 text-end">
															<a  className="nect">NEXT PAGE</a>
														</div>
													</div>
												</div>
											</div>
											<div className={"tab-pane fade " + ((this.state.activeTab == "nav-sell") ? 'show active' : '')} id="nav-sell" role="tabpanel" aria-labelledby="nav-sell-tab" tabIndex="0">
												<div className="tbl-head">
													<div className="row align-items-center">
														<div className="col-3">
															{strings["Seller"]}
														</div>
														<div className="col-7">
															{strings["Amount of FUTI Listed for Sale"]}
														</div>
														<div className="col-2">

														</div>
													</div>
												</div>
												<div className="foot">
													<div className="row align-items-center">
														<div className="col-8 col-md-6">
															<a href='javascript:void(0)' onClick={() => this.setState({ modal_createBuy: true })} data-bs-toggle="modal" data-bs-target="#createBuy">CREATE AD TO BUY</a> <img src="assets/images/louder.svg" className="mx-2" alt="" />
															<a href='javascript:void(0)' onClick={() => this.setState({ modal_mySellOrder: true })} data-bs-toggle="modal" data-bs-target="#mySellOrder">My Sell Order</a>
														</div>
														<div className="col-4 col-md-6 text-end">
															<a  className="nect">NEXT PAGE</a>
														</div>
													</div>
												</div>
											</div>
										</div>*/}
									</div>
								</div>

								{/* <!-- Modal Buy --> */}
								<Modal open={this.state.modal_myBuyOrder}>
									<div className="modal-dialog modal-dialog-centered">
										<div className="modal-content">
											<div className="modal-header border-0">
												<h1 className="modal-title" id="exampleModalLabel">{strings["MY BUY ORDER"]}</h1>
											</div>
											<div className="modal-body">
												<div className="my-5">
													<div className="bar mb-3">
														{strings["Purchased Amount"]}: 0.0000
													</div>
													<div className="bar mb-2">
														{strings["Amount On Listing"]}: 0.0000
													</div>
												</div>
												<div className="text-center mb-4">
													<button onClick={() => this.setState({ modal_myBuyOrder: false })} type="button" className="btn btn-primary rounded-pill" data-bs-dismiss="modal">{strings["close"]}</button>
												</div>
											</div>
										</div>
									</div>
								</Modal>

								{/* <!-- Modal Sell --> */}
								<Modal open={this.state.modal_mySellOrder}>
									<div className="modal-dialog modal-dialog-centered">
										<div className="modal-content">
											<div className="modal-header border-0">
												<h1 className="modal-title" id="exampleModalLabel">{strings["My Sell Order"]}</h1>
											</div>
											<div className="modal-body">
												<div className="my-5">
													<div className="bar mb-3">
														{strings["Sold Amount"]}: 0.0000
													</div>
													<div className="bar mb-2">
														{strings["Amount On Listing"]}: 0.0000
													</div>
												</div>
												<div className="text-center mb-4">
													<button onClick={() => this.setState({ modal_mySellOrder: false })} type="button" className="btn btn-primary rounded-pill" data-bs-dismiss="modal">{strings["close"]}</button>
												</div>
											</div>
										</div>
									</div>
								</Modal>

								{/* <!-- Modal Create Sell --> */}
								<Modal open={this.state.modal_createSell}>
									<div className="modal-dialog modal-dialog-centered">
										<div className="modal-content">
											<div className="modal-header border-0">
												<h1 className="modal-title" id="exampleModalLabel">{strings["Creat AD to Sell"]}</h1>
												<div className="fudi">
													<img src="assets/images/fude.png" className="pic" alt="" /> {strings["FUTE"]}:0.0000
												</div>
											</div>
											<div className="modal-body">
												<div className="my-5">
													<p>{strings["After each completed transaction, FUTI will instantly reflect in your pocket balance."]}</p>
													<div className="mb-3">
														<input className="form-control" type="text" placeholder={strings["Enter FUTI Amount"]} />
													</div>
												</div>
												<div className="text-center pt-4 mb-4">
													<button onClick={() => this.setState({ modal_createSell: false })} type="submit" className="btn btn-primary rounded-pill" data-bs-dismiss="modal">{strings["Exchange Now"]}</button>
												</div>
											</div>
										</div>
									</div>
								</Modal>

								{/* <!-- Modal Create Buy --> */}
								<Modal open={this.state.modal_createBuy}>
									<div className="modal-dialog modal-dialog-centered">
										<div className="modal-content">
											<div className="modal-header border-0">
												<h1 className="modal-title" id="exampleModalLabel">{strings["Creat AD to Buy"]}</h1>
												<div className="fudi">
													<img src="assets/images/fude.png" className="pic" alt="" /> {strings["FUTE"]}:0.0000
												</div>
											</div>
											<div className="modal-body">
												<div className="my-5">
													<p>{strings["After each completed transaction, FUTE will instantly reflect in your pocket balance."]}</p>
													<div className="mb-3">
														<input className="form-control" type="text" placeholder={strings["Enter FUTI Amount"]} />
													</div>
												</div>
												<div className="text-center pt-4 mb-4">
													<button onClick={() => this.setState({ modal_createBuy: false })} type="submit" className="btn btn-primary rounded-pill" data-bs-dismiss="modal">{strings["Exchange Now"]}</button>
												</div>
											</div>
										</div>
									</div>
								</Modal>
							</>

							<LatestNews />

						</div>
					</div>

				</div >
				<SideBarMobile />
			</>
		);
	}
}

export default P2PListingScreen;
