import axios from 'axios';
import $ from 'jquery';
import confirm from 'jquery-confirm';
import { toast } from 'react-toastify';
import strings from './../Language';
import { api } from '../config/api';

export function alertMessage(message) {
    $.alert({
        title: strings.error,
        content: message,
        type: 'red',
        typeAnimated: true,
    });
}

export function warningMessage(message) {
    $.alert({
        title: "",
        content: message,
        type: 'orange',
        typeAnimated: true,
    });
}

export function tostMessage(message) {
    toast.success(message, {
        position: "top-center",
        autoClose: !0,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: 0,
    });
}

export function successMessage(message) {
    $.alert({
        title: strings.success,
        content: message,
        type: 'green',
        typeAnimated: true,
    });
}

export async function storeUserData(token, context) {
    await axios({
        method: 'GET',
        url: api.profile,
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json;multipart/form-data;boundary=SOME_BOUNDARY',
        }
    }).then(function (ress) {
        if (ress.data && ress.data.error === 200) {
            localStorage.setItem('user_data', JSON.stringify(ress.data.data));
        } else {
            localStorage.clear();
            // window.location.reload(false);
            window.location.replace('/');
        }
    }).catch(function (e) {
        if (e.response && e.response.status === 401) {
            localStorage.clear();
            window.location.replace('/');
        }
        // this.props.history.go('/home');
    });
}

export function getUserData() {
    const userData = localStorage.getItem('user_data');
    if (userData) {
        return JSON.parse(userData);
    } else {
        return [];
    }
};

export async function getToken() {
    const user_token = await localStorage.getItem('user_token');
    if (user_token) {
        return user_token;
    } else {
        localStorage.clear();
        window.location.replace('/');
        // this.props.history.go('/home');
        return false;
    }
};

export function number_format(number, decimals, dec_point, thousands_sep) {
    // Strip all characters but numerical ones.
    number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
        prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
        sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
        dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
        s = '',
        toFixedFix = function (n, prec) {
            var k = Math.pow(10, prec);
            return '' + Math.round(n * k) / k;
        };
    // Fix for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

export const Network = {
    fsc: {
        chainId: `0x${Number(663).toString(16)}`,
        chainName: "Futura Smart Chain Mainnet",
        nativeCurrency: {
            name: "FSC",
            symbol: "FSC",
            decimals: 18
        },
        rpcUrls: ["https://rpc.fscscan.com"],
        blockExplorerUrls: ["https://fscscan.com"]
    },
}